export const locations = {
    "counties": [
        {
            "name": "Rīga",
            "code": "COUNTY_RIGA"
        },
        {
            "name": "Jelgava",
            "code": "COUNTY_JELGAVA"
        },
        {
            "name": "Liepāja",
            "code": "COUNTY_LIEPAJA"
        },
        {
            "name": "Ventspils",
            "code": "COUNTY_VENTSPILS"
        },
        {
            "name": "Jūrmala",
            "code": "COUNTY_JURMALA"
        },
        {
            "name": "Daugavpils",
            "code": "COUNTY_DAUGAVPILS"
        },
        {
            "name": "Rēzekne",
            "code": "COUNTY_REZEKNE"
        },
        {
            "name": "Valmiera",
            "code": "COUNTY_VALMIERA"
        },
        {
            "name": "Jēkabpils",
            "code": "COUNTY_JEKABPILS"
        },
        {
            "name": "Ciblas nov.",
            "code": "COUNTY_CIBLAS_NOV"
        },
        {
            "name": "Amatas nov.",
            "code": "COUNTY_AMATAS_NOV"
        },
        {
            "name": "Aizkraukles nov.",
            "code": "COUNTY_AIZKRAUKLES_NOV"
        },
        {
            "name": "Krāslavas nov.",
            "code": "COUNTY_KRASLAVAS_NOV"
        },
        {
            "name": "Brocēnu nov.",
            "code": "COUNTY_BROCENU_NOV"
        },
        {
            "name": "Preiļu nov.",
            "code": "COUNTY_PREILU_NOV"
        },
        {
            "name": "Durbes nov.",
            "code": "COUNTY_DURBES_NOV"
        },
        {
            "name": "Vārkavas nov.",
            "code": "COUNTY_VARKAVAS_NOV"
        },
        {
            "name": "Līvānu nov.",
            "code": "COUNTY_LIVANU_NOV"
        },
        {
            "name": "Ķeguma nov.",
            "code": "COUNTY_KEGUMA_NOV"
        },
        {
            "name": "Iecavas nov.",
            "code": "COUNTY_IECAVAS_NOV"
        },
        {
            "name": "Ogres nov.",
            "code": "COUNTY_OGRES_NOV"
        },
        {
            "name": "Tērvetes nov.",
            "code": "COUNTY_TERVETES_NOV"
        },
        {
            "name": "Zilupes nov.",
            "code": "COUNTY_ZILUPES_NOV"
        },
        {
            "name": "Ozolnieku nov.",
            "code": "COUNTY_OZOLNIEKU_NOV"
        },
        {
            "name": "Kandavas nov.",
            "code": "COUNTY_KANDAVAS_NOV"
        },
        {
            "name": "Siguldas nov.",
            "code": "COUNTY_SIGULDAS_NOV"
        },
        {
            "name": "Ilūkstes nov.",
            "code": "COUNTY_ILUKSTES_NOV"
        },
        {
            "name": "Ikšķiles nov.",
            "code": "COUNTY_IKSKILES_NOV"
        },
        {
            "name": "Aglonas nov.",
            "code": "COUNTY_AGLONAS_NOV"
        },
        {
            "name": "Aizputes nov.",
            "code": "COUNTY_AIZPUTES_NOV"
        },
        {
            "name": "Aknīstes nov.",
            "code": "COUNTY_AKNISTES_NOV"
        },
        {
            "name": "Alojas nov.",
            "code": "COUNTY_ALOJAS_NOV"
        },
        {
            "name": "Alsungas nov.",
            "code": "COUNTY_ALSUNGAS_NOV"
        },
        {
            "name": "Alūksnes nov.",
            "code": "COUNTY_ALUKSNES_NOV"
        },
        {
            "name": "Apes nov.",
            "code": "COUNTY_APES_NOV"
        },
        {
            "name": "Lubānas nov.",
            "code": "COUNTY_LUBANAS_NOV"
        },
        {
            "name": "Ādažu nov.",
            "code": "COUNTY_ADAZU_NOV"
        },
        {
            "name": "Carnikavas nov.",
            "code": "COUNTY_CARNIKAVAS_NOV"
        },
        {
            "name": "Garkalnes nov.",
            "code": "COUNTY_GARKALNES_NOV"
        },
        {
            "name": "Rojas nov.",
            "code": "COUNTY_ROJAS_NOV"
        },
        {
            "name": "Mērsraga nov.",
            "code": "COUNTY_MERSRAGA_NOV"
        },
        {
            "name": "Baldones nov.",
            "code": "COUNTY_BALDONES_NOV"
        },
        {
            "name": "Auces nov.",
            "code": "COUNTY_AUCES_NOV"
        },
        {
            "name": "Babītes nov.",
            "code": "COUNTY_BABITES_NOV"
        },
        {
            "name": "Baltinavas nov.",
            "code": "COUNTY_BALTINAVAS_NOV"
        },
        {
            "name": "Balvu nov.",
            "code": "COUNTY_BALVU_NOV"
        },
        {
            "name": "Bauskas nov.",
            "code": "COUNTY_BAUSKAS_NOV"
        },
        {
            "name": "Beverīnas nov.",
            "code": "COUNTY_BEVERINAS_NOV"
        },
        {
            "name": "Cēsu nov.",
            "code": "COUNTY_CESU_NOV"
        },
        {
            "name": "Cesvaines nov.",
            "code": "COUNTY_CESVAINES_NOV"
        },
        {
            "name": "Dagdas nov.",
            "code": "COUNTY_DAGDAS_NOV"
        },
        {
            "name": "Daugavpils nov.",
            "code": "COUNTY_DAUGAVPILS_NOV"
        },
        {
            "name": "Dobeles nov.",
            "code": "COUNTY_DOBELES_NOV"
        },
        {
            "name": "Dundagas nov.",
            "code": "COUNTY_DUNDAGAS_NOV"
        },
        {
            "name": "Engures nov.",
            "code": "COUNTY_ENGURES_NOV"
        },
        {
            "name": "Grobiņas nov.",
            "code": "COUNTY_GROBINAS_NOV"
        },
        {
            "name": "Gulbenes nov.",
            "code": "COUNTY_GULBENES_NOV"
        },
        {
            "name": "Jaunjelgavas nov.",
            "code": "COUNTY_JAUNJELGAVAS_NOV"
        },
        {
            "name": "Jaunpils nov.",
            "code": "COUNTY_JAUNPILS_NOV"
        },
        {
            "name": "Jēkabpils nov.",
            "code": "COUNTY_JEKABPILS_NOV"
        },
        {
            "name": "Jelgavas nov.",
            "code": "COUNTY_JELGAVAS_NOV"
        },
        {
            "name": "Kārsavas nov.",
            "code": "COUNTY_KARSAVAS_NOV"
        },
        {
            "name": "Kokneses nov.",
            "code": "COUNTY_KOKNESES_NOV"
        },
        {
            "name": "Krimuldas nov.",
            "code": "COUNTY_KRIMULDAS_NOV"
        },
        {
            "name": "Krustpils nov.",
            "code": "COUNTY_KRUSTPILS_NOV"
        },
        {
            "name": "Kuldīgas nov.",
            "code": "COUNTY_KULDIGAS_NOV"
        },
        {
            "name": "Ķekavas nov.",
            "code": "COUNTY_KEKAVAS_NOV"
        },
        {
            "name": "Līgatnes nov.",
            "code": "COUNTY_LIGATNES_NOV"
        },
        {
            "name": "Limbažu nov.",
            "code": "COUNTY_LIMBAZU_NOV"
        },
        {
            "name": "Ludzas nov.",
            "code": "COUNTY_LUDZAS_NOV"
        },
        {
            "name": "Madonas nov.",
            "code": "COUNTY_MADONAS_NOV"
        },
        {
            "name": "Mālpils nov.",
            "code": "COUNTY_MALPILS_NOV"
        },
        {
            "name": "Mārupes nov.",
            "code": "COUNTY_MARUPES_NOV"
        },
        {
            "name": "Mazsalacas nov.",
            "code": "COUNTY_MAZSALACAS_NOV"
        },
        {
            "name": "Naukšēnu nov.",
            "code": "COUNTY_NAUKSENU_NOV"
        },
        {
            "name": "Neretas nov.",
            "code": "COUNTY_NERETAS_NOV"
        },
        {
            "name": "Nīcas nov.",
            "code": "COUNTY_NICAS_NOV"
        },
        {
            "name": "Olaines nov.",
            "code": "COUNTY_OLAINES_NOV"
        },
        {
            "name": "Pārgaujas nov.",
            "code": "COUNTY_PARGAUJAS_NOV"
        },
        {
            "name": "Pāvilostas nov.",
            "code": "COUNTY_PAVILOSTAS_NOV"
        },
        {
            "name": "Pļaviņu nov.",
            "code": "COUNTY_PLAVINU_NOV"
        },
        {
            "name": "Priekules nov.",
            "code": "COUNTY_PRIEKULES_NOV"
        },
        {
            "name": "Priekuļu nov.",
            "code": "COUNTY_PRIEKULU_NOV"
        },
        {
            "name": "Rēzeknes nov.",
            "code": "COUNTY_REZEKNES_NOV"
        },
        {
            "name": "Rucavas nov.",
            "code": "COUNTY_RUCAVAS_NOV"
        },
        {
            "name": "Rugāju nov.",
            "code": "COUNTY_RUGAJU_NOV"
        },
        {
            "name": "Rundāles nov.",
            "code": "COUNTY_RUNDALES_NOV"
        },
        {
            "name": "Rūjienas nov.",
            "code": "COUNTY_RUJIENAS_NOV"
        },
        {
            "name": "Salacgrīvas nov.",
            "code": "COUNTY_SALACGRIVAS_NOV"
        },
        {
            "name": "Salas nov.",
            "code": "COUNTY_SALAS_NOV"
        },
        {
            "name": "Saldus nov.",
            "code": "COUNTY_SALDUS_NOV"
        },
        {
            "name": "Skrīveru nov.",
            "code": "COUNTY_SKRIVERU_NOV"
        },
        {
            "name": "Skrundas nov.",
            "code": "COUNTY_SKRUNDAS_NOV"
        },
        {
            "name": "Smiltenes nov.",
            "code": "COUNTY_SMILTENES_NOV"
        },
        {
            "name": "Strenču nov.",
            "code": "COUNTY_STRENCU_NOV"
        },
        {
            "name": "Talsu nov.",
            "code": "COUNTY_TALSU_NOV"
        },
        {
            "name": "Tukuma nov.",
            "code": "COUNTY_TUKUMA_NOV"
        },
        {
            "name": "Vaiņodes nov.",
            "code": "COUNTY_VAINODES_NOV"
        },
        {
            "name": "Valkas nov.",
            "code": "COUNTY_VALKAS_NOV"
        },
        {
            "name": "Kocēnu nov.",
            "code": "COUNTY_KOCENU_NOV"
        },
        {
            "name": "Varakļānu nov.",
            "code": "COUNTY_VARAKLANU_NOV"
        },
        {
            "name": "Vecpiebalgas nov.",
            "code": "COUNTY_VECPIEBALGAS_NOV"
        },
        {
            "name": "Vecumnieku nov.",
            "code": "COUNTY_VECUMNIEKU_NOV"
        },
        {
            "name": "Ventspils nov.",
            "code": "COUNTY_VENTSPILS_NOV"
        },
        {
            "name": "Viesītes nov.",
            "code": "COUNTY_VIESITES_NOV"
        },
        {
            "name": "Viļakas nov.",
            "code": "COUNTY_VILAKAS_NOV"
        },
        {
            "name": "Viļānu nov.",
            "code": "COUNTY_VILANU_NOV"
        },
        {
            "name": "Burtnieku nov.",
            "code": "COUNTY_BURTNIEKU_NOV"
        },
        {
            "name": "Ērgļu nov.",
            "code": "COUNTY_ERGLU_NOV"
        },
        {
            "name": "Sējas nov.",
            "code": "COUNTY_SEJAS_NOV"
        },
        {
            "name": "Inčukalna nov.",
            "code": "COUNTY_INCUKALNA_NOV"
        },
        {
            "name": "Jaunpiebalgas nov.",
            "code": "COUNTY_JAUNPIEBALGAS_NOV"
        },
        {
            "name": "Saulkrastu nov.",
            "code": "COUNTY_SAULKRASTU_NOV"
        },
        {
            "name": "Raunas nov.",
            "code": "COUNTY_RAUNAS_NOV"
        },
        {
            "name": "Lielvārdes nov.",
            "code": "COUNTY_LIELVARDES_NOV"
        },
        {
            "name": "Ropažu nov.",
            "code": "COUNTY_ROPAZU_NOV"
        },
        {
            "name": "Riebiņu nov.",
            "code": "COUNTY_RIEBINU_NOV"
        },
        {
            "name": "Stopiņu nov.",
            "code": "COUNTY_STOPINU_NOV"
        },
        {
            "name": "Salaspils nov.",
            "code": "COUNTY_SALASPILS_NOV"
        }
    ],
    "cities": [
        {
            "name": "Āgenskalns",
            "code": "CITY_AGENSKALNS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Aplokciems",
            "code": "CITY_APLOKCIEMS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Atgāzene",
            "code": "CITY_ATGAZENE",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Avoti",
            "code": "CITY_AVOTI",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Beberbeki",
            "code": "CITY_BEBERBEKI",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Berģi",
            "code": "CITY_BERGI",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Bieriņi",
            "code": "CITY_BIERINI",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Bišumuiža",
            "code": "CITY_BISUMUIZA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Bolderāja",
            "code": "CITY_BOLDERAJA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Brasa",
            "code": "CITY_BRASA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Bukulti",
            "code": "CITY_BUKULTI",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Buļļi",
            "code": "CITY_BULLI",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Centrs",
            "code": "CITY_CENTRS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Čiekurkalns",
            "code": "CITY_CIEKURKALNS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Dārzciems",
            "code": "CITY_DARZCIEMS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Dārziņi",
            "code": "CITY_DARZINI",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Daugavgrīva",
            "code": "CITY_DAUGAVGRIVA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Dreiliņi",
            "code": "CITY_DREILINI",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Dzirciems",
            "code": "CITY_DZIRCIEMS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Grīziņkalns",
            "code": "CITY_GRIZINKALNS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Iļģuciems",
            "code": "CITY_ILGUCIEMS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Imanta",
            "code": "CITY_IMANTA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Jaunciems",
            "code": "CITY_JAUNCIEMS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Jaunmīlgrāvis",
            "code": "CITY_JAUNMILGRAVIS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Jugla",
            "code": "CITY_JUGLA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Katlakalns",
            "code": "CITY_KATLAKALNS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Ķengarags",
            "code": "CITY_KENGARAGS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Ķīpsala",
            "code": "CITY_KIPSALA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Kleisti",
            "code": "CITY_KLEISTI",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Klīversala",
            "code": "CITY_KLIVERSALA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Krasta masīvs",
            "code": "CITY_KRASTA_MASIVS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Kundziņsala",
            "code": "CITY_KUNDZINSALA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Lucavsala",
            "code": "CITY_LUCAVSALA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Mangaļu pussala",
            "code": "CITY_MANGALU_PUSSALA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Maskavas priekšpilsēta",
            "code": "CITY_MASKAVAS_PRIEKSPILSETA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Mežaparks",
            "code": "CITY_MEZAPARKS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Mežciems",
            "code": "CITY_MEZCIEMS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Mīlgrāvis",
            "code": "CITY_MILGRAVIS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Mūkusala",
            "code": "CITY_MUKUSALA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Ozolciems",
            "code": "CITY_OZOLCIEMS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Pārdaugava",
            "code": "CITY_PARDAUGAVA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Pētersala",
            "code": "CITY_PETERSALA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Pļavnieki",
            "code": "CITY_PLAVNIEKI",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Pleskodāle-Šampēteris",
            "code": "CITY_PLESKODALE_SAMPETERIS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Purvciems",
            "code": "CITY_PURVCIEMS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Rumbula",
            "code": "CITY_RUMBULA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Sarkandaugava",
            "code": "CITY_SARKANDAUGAVA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Skanste",
            "code": "CITY_SKANSTE",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Spilve",
            "code": "CITY_SPILVE",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Šķirotava",
            "code": "CITY_SKIROTAVA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Šmerlis",
            "code": "CITY_SMERLIS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Teika",
            "code": "CITY_TEIKA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Torņakalns",
            "code": "CITY_TORNKALNS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Trīsciems",
            "code": "CITY_TRISCIEMS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Vecāķi",
            "code": "CITY_VECAKI",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Vecdaugava",
            "code": "CITY_VECDAUGAVA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Vecmīlgrāvis",
            "code": "CITY_VECMILGRAVIS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Vecrīga",
            "code": "CITY_VECRIGA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Voleri",
            "code": "CITY_VOLERI",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Zaķusala",
            "code": "CITY_ZAKUSALA",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Zasulauks",
            "code": "CITY_ZASULAUKS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Ziepniekkalns",
            "code": "CITY_ZIEPNIEKKALNS",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Zolitūde",
            "code": "CITY_ZOLITUDE",
            "county": {
                "code": "COUNTY_RIGA"
            }
        },
        {
            "name": "Jelgava",
            "code": "CITY_JELGAVA",
            "county": {
                "code": "COUNTY_JELGAVA"
            }
        },
        {
            "name": "Liepāja",
            "code": "CITY_LIEPAJA",
            "county": {
                "code": "COUNTY_LIEPAJA"
            }
        },
        {
            "name": "Ventspils",
            "code": "CITY_VENTSPILS",
            "county": {
                "code": "COUNTY_VENTSPILS"
            }
        },
        {
            "name": "Asari",
            "code": "CITY_ASARI",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Bulduri",
            "code": "CITY_BULDURI",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Buļļuciems",
            "code": "CITY_BULLUCIEMS",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Druvciems",
            "code": "CITY_DRUVCIEMS",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Dubulti",
            "code": "CITY_DUBULTI",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Dzintari",
            "code": "CITY_DZINTARI",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Jaundubulti",
            "code": "CITY_JAUNDUBULTI",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Jaunķemeri",
            "code": "CITY_JAUNKEMERI",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Kaugurciems",
            "code": "CITY_KAUGURCIEMS",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Kauguri",
            "code": "CITY_KAUGURI",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Ķemeri",
            "code": "CITY_KEMERI",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Lielupe",
            "code": "CITY_LIELUPE",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Majori",
            "code": "CITY_MAJORI",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Melluži",
            "code": "CITY_MELLUZI",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Priedaine",
            "code": "CITY_PRIEDAINE",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Pumpuri",
            "code": "CITY_PUMPURI",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Sloka",
            "code": "CITY_SLOKA",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Vaivari",
            "code": "CITY_VAIVARI",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Valteri",
            "code": "CITY_VALTERI",
            "county": {
                "code": "COUNTY_JURMALA"
            }
        },
        {
            "name": "Daugavpils",
            "code": "CITY_DAUGAVPILS",
            "county": {
                "code": "COUNTY_DAUGAVPILS"
            }
        },
        {
            "name": "Rēzekne",
            "code": "CITY_REZEKNE",
            "county": {
                "code": "COUNTY_REZEKNE"
            }
        },
        {
            "name": "Valmiera",
            "code": "CITY_VALMIERA",
            "county": {
                "code": "COUNTY_VALMIERA"
            }
        },
        {
            "name": "Jēkabpils",
            "code": "CITY_JEKABPILS",
            "county": {
                "code": "COUNTY_JEKABPILS"
            }
        },
        {
            "name": "Zvirgzdenes pag.",
            "code": "CITY_ZVIRGZDENES_PAG",
            "county": {
                "code": "COUNTY_CIBLAS_NOV"
            }
        },
        {
            "name": "Blontu pag.",
            "code": "CITY_BLONTU_PAG",
            "county": {
                "code": "COUNTY_CIBLAS_NOV"
            }
        },
        {
            "name": "Līdumnieku pag.",
            "code": "CITY_LIDUMNIEKU_PAG",
            "county": {
                "code": "COUNTY_CIBLAS_NOV"
            }
        },
        {
            "name": "Ciblas pag.",
            "code": "CITY_CIBLAS_PAG",
            "county": {
                "code": "COUNTY_CIBLAS_NOV"
            }
        },
        {
            "name": "Pušmucovas pag.",
            "code": "CITY_PUSMUCOVAS_PAG",
            "county": {
                "code": "COUNTY_CIBLAS_NOV"
            }
        },
        {
            "name": "Drabešu pag.",
            "code": "CITY_DRABESU_PAG",
            "county": {
                "code": "COUNTY_AMATAS_NOV"
            }
        },
        {
            "name": "Zaubes pag.",
            "code": "CITY_ZAUBES_PAG",
            "county": {
                "code": "COUNTY_AMATAS_NOV"
            }
        },
        {
            "name": "Nītaures pag.",
            "code": "CITY_NITAURES_PAG",
            "county": {
                "code": "COUNTY_AMATAS_NOV"
            }
        },
        {
            "name": "Amatas pag.",
            "code": "CITY_AMATAS_PAG",
            "county": {
                "code": "COUNTY_AMATAS_NOV"
            }
        },
        {
            "name": "Līvi",
            "code": "CITY_LIVI",
            "county": {
                "code": "COUNTY_AMATAS_NOV"
            }
        },
        {
            "name": "Aizkraukle",
            "code": "CITY_AIZKRAUKLE",
            "county": {
                "code": "COUNTY_AIZKRAUKLES_NOV"
            }
        },
        {
            "name": "Aizkraukles pag.",
            "code": "CITY_AIZKRAUKLES_PAG",
            "county": {
                "code": "COUNTY_AIZKRAUKLES_NOV"
            }
        },
        {
            "name": "Krāslava",
            "code": "CITY_KRASLAVA",
            "county": {
                "code": "COUNTY_KRASLAVAS_NOV"
            }
        },
        {
            "name": "Krāslavas pag.",
            "code": "CITY_KRASLAVAS_PAG",
            "county": {
                "code": "COUNTY_KRASLAVAS_NOV"
            }
        },
        {
            "name": "Skaistas pag.",
            "code": "CITY_SKAISTAS_PAG",
            "county": {
                "code": "COUNTY_KRASLAVAS_NOV"
            }
        },
        {
            "name": "Ūdrīšu pag.",
            "code": "CITY_UDRISU_PAG",
            "county": {
                "code": "COUNTY_KRASLAVAS_NOV"
            }
        },
        {
            "name": "Kombuļu pag.",
            "code": "CITY_KOMBULU_PAG",
            "county": {
                "code": "COUNTY_KRASLAVAS_NOV"
            }
        },
        {
            "name": "Robežnieku pag.",
            "code": "CITY_ROBEZNIEKU_PAG",
            "county": {
                "code": "COUNTY_KRASLAVAS_NOV"
            }
        },
        {
            "name": "Indras pag.",
            "code": "CITY_INDRAS_PAG",
            "county": {
                "code": "COUNTY_KRASLAVAS_NOV"
            }
        },
        {
            "name": "Kalniešu pag.",
            "code": "CITY_KALNIESU_PAG",
            "county": {
                "code": "COUNTY_KRASLAVAS_NOV"
            }
        },
        {
            "name": "Izvaltas pag.",
            "code": "CITY_IZVALTAS_PAG",
            "county": {
                "code": "COUNTY_KRASLAVAS_NOV"
            }
        },
        {
            "name": "Kaplavas pag.",
            "code": "CITY_KAPLAVAS_PAG",
            "county": {
                "code": "COUNTY_KRASLAVAS_NOV"
            }
        },
        {
            "name": "Piedrujas pag.",
            "code": "CITY_PIEDRUJAS_PAG",
            "county": {
                "code": "COUNTY_KRASLAVAS_NOV"
            }
        },
        {
            "name": "Aulejas pag.",
            "code": "CITY_AULEJAS_PAG",
            "county": {
                "code": "COUNTY_KRASLAVAS_NOV"
            }
        },
        {
            "name": "Indra",
            "code": "CITY_INDRA",
            "county": {
                "code": "COUNTY_KRASLAVAS_NOV"
            }
        },
        {
            "name": "Brocēni",
            "code": "CITY_BROCENI",
            "county": {
                "code": "COUNTY_BROCENU_NOV"
            }
        },
        {
            "name": "Remtes pag.",
            "code": "CITY_REMTES_PAG",
            "county": {
                "code": "COUNTY_BROCENU_NOV"
            }
        },
        {
            "name": "Blīdenes pag.",
            "code": "CITY_BLIDENES_PAG",
            "county": {
                "code": "COUNTY_BROCENU_NOV"
            }
        },
        {
            "name": "Gaiķu pag.",
            "code": "CITY_GAIKU_PAG",
            "county": {
                "code": "COUNTY_BROCENU_NOV"
            }
        },
        {
            "name": "Cieceres pag.",
            "code": "CITY_CIECERES_PAG",
            "county": {
                "code": "COUNTY_BROCENU_NOV"
            }
        },
        {
            "name": "Preiļi",
            "code": "CITY_PREILI",
            "county": {
                "code": "COUNTY_PREILU_NOV"
            }
        },
        {
            "name": "Aizkalnes pag.",
            "code": "CITY_AIZKALNES_PAG",
            "county": {
                "code": "COUNTY_PREILU_NOV"
            }
        },
        {
            "name": "Preiļu pag.",
            "code": "CITY_PREILU_PAG",
            "county": {
                "code": "COUNTY_PREILU_NOV"
            }
        },
        {
            "name": "Saunas pag.",
            "code": "CITY_SAUNAS_PAG",
            "county": {
                "code": "COUNTY_PREILU_NOV"
            }
        },
        {
            "name": "Pelēču pag.",
            "code": "CITY_PELECU_PAG",
            "county": {
                "code": "COUNTY_PREILU_NOV"
            }
        },
        {
            "name": "Durbe",
            "code": "CITY_DURBE",
            "county": {
                "code": "COUNTY_DURBES_NOV"
            }
        },
        {
            "name": "Durbes pag.",
            "code": "CITY_DURBES_PAG",
            "county": {
                "code": "COUNTY_DURBES_NOV"
            }
        },
        {
            "name": "Vecpils pag.",
            "code": "CITY_VECPILS_PAG",
            "county": {
                "code": "COUNTY_DURBES_NOV"
            }
        },
        {
            "name": "Dunalkas pag.",
            "code": "CITY_DUNALKAS_PAG",
            "county": {
                "code": "COUNTY_DURBES_NOV"
            }
        },
        {
            "name": "Tadaiķu pag.",
            "code": "CITY_TADAIKU_PAG",
            "county": {
                "code": "COUNTY_DURBES_NOV"
            }
        },
        {
            "name": "Upmalas pag.",
            "code": "CITY_UPMALAS_PAG",
            "county": {
                "code": "COUNTY_VARKAVAS_NOV"
            }
        },
        {
            "name": "Rožkalnu pag.",
            "code": "CITY_ROZKALNU_PAG",
            "county": {
                "code": "COUNTY_VARKAVAS_NOV"
            }
        },
        {
            "name": "Vārkavas pag.",
            "code": "CITY_VARKAVAS_PAG",
            "county": {
                "code": "COUNTY_VARKAVAS_NOV"
            }
        },
        {
            "name": "Līvāni",
            "code": "CITY_LIVANI",
            "county": {
                "code": "COUNTY_LIVANU_NOV"
            }
        },
        {
            "name": "Rožupes pag.",
            "code": "CITY_ROZUPES_PAG",
            "county": {
                "code": "COUNTY_LIVANU_NOV"
            }
        },
        {
            "name": "Sutru pag.",
            "code": "CITY_SUTRU_PAG",
            "county": {
                "code": "COUNTY_LIVANU_NOV"
            }
        },
        {
            "name": "Rudzātu pag.",
            "code": "CITY_RUDZATU_PAG",
            "county": {
                "code": "COUNTY_LIVANU_NOV"
            }
        },
        {
            "name": "Turku pag.",
            "code": "CITY_TURKU_PAG",
            "county": {
                "code": "COUNTY_LIVANU_NOV"
            }
        },
        {
            "name": "Jersikas pag.",
            "code": "CITY_JERSIKAS_PAG",
            "county": {
                "code": "COUNTY_LIVANU_NOV"
            }
        },
        {
            "name": "Ķegums",
            "code": "CITY_KEGUMS",
            "county": {
                "code": "COUNTY_KEGUMA_NOV"
            }
        },
        {
            "name": "Tomes pag.",
            "code": "CITY_TOMES_PAG",
            "county": {
                "code": "COUNTY_KEGUMA_NOV"
            }
        },
        {
            "name": "Birzgales pag.",
            "code": "CITY_BIRZGALES_PAG",
            "county": {
                "code": "COUNTY_KEGUMA_NOV"
            }
        },
        {
            "name": "Rembates pag.",
            "code": "CITY_REMBATES_PAG",
            "county": {
                "code": "COUNTY_KEGUMA_NOV"
            }
        },
        {
            "name": "Misa",
            "code": "CITY_MISA",
            "county": {
                "code": "COUNTY_IECAVAS_NOV"
            }
        },
        {
            "name": "Dzimtmisa",
            "code": "CITY_DZIMTMISA",
            "county": {
                "code": "COUNTY_IECAVAS_NOV"
            }
        },
        {
            "name": "Dimzukalns",
            "code": "CITY_DIMZUKALNS",
            "county": {
                "code": "COUNTY_IECAVAS_NOV"
            }
        },
        {
            "name": "Zālīte",
            "code": "CITY_ZALITE",
            "county": {
                "code": "COUNTY_IECAVAS_NOV"
            }
        },
        {
            "name": "Dzelzāmurs",
            "code": "CITY_DZELZAMURS",
            "county": {
                "code": "COUNTY_IECAVAS_NOV"
            }
        },
        {
            "name": "Rosme",
            "code": "CITY_ROSME",
            "county": {
                "code": "COUNTY_IECAVAS_NOV"
            }
        },
        {
            "name": "Iecava",
            "code": "CITY_IECAVA",
            "county": {
                "code": "COUNTY_IECAVAS_NOV"
            }
        },
        {
            "name": "Zorģi",
            "code": "CITY_ZORGI",
            "county": {
                "code": "COUNTY_IECAVAS_NOV"
            }
        },
        {
            "name": "Audrupi",
            "code": "CITY_AUDRUPI",
            "county": {
                "code": "COUNTY_IECAVAS_NOV"
            }
        },
        {
            "name": "Ogre",
            "code": "CITY_OGRE",
            "county": {
                "code": "COUNTY_OGRES_NOV"
            }
        },
        {
            "name": "Ķeipenes pag.",
            "code": "CITY_KEIPENES_PAG",
            "county": {
                "code": "COUNTY_OGRES_NOV"
            }
        },
        {
            "name": "Ogresgala pag.",
            "code": "CITY_OGRESGALA_PAG",
            "county": {
                "code": "COUNTY_OGRES_NOV"
            }
        },
        {
            "name": "Taurupes pag.",
            "code": "CITY_TAURUPES_PAG",
            "county": {
                "code": "COUNTY_OGRES_NOV"
            }
        },
        {
            "name": "Madlienas pag.",
            "code": "CITY_MADLIENAS_PAG",
            "county": {
                "code": "COUNTY_OGRES_NOV"
            }
        },
        {
            "name": "Krapes pag.",
            "code": "CITY_KRAPES_PAG",
            "county": {
                "code": "COUNTY_OGRES_NOV"
            }
        },
        {
            "name": "Mazozolu pag.",
            "code": "CITY_MAZOZOLU_PAG",
            "county": {
                "code": "COUNTY_OGRES_NOV"
            }
        },
        {
            "name": "Suntažu pag.",
            "code": "CITY_SUNTAZU_PAG",
            "county": {
                "code": "COUNTY_OGRES_NOV"
            }
        },
        {
            "name": "Lauberes pag.",
            "code": "CITY_LAUBERES_PAG",
            "county": {
                "code": "COUNTY_OGRES_NOV"
            }
        },
        {
            "name": "Meņģeles pag.",
            "code": "CITY_MENGELES_PAG",
            "county": {
                "code": "COUNTY_OGRES_NOV"
            }
        },
        {
            "name": "Ogresgals",
            "code": "CITY_OGRESGALS",
            "county": {
                "code": "COUNTY_OGRES_NOV"
            }
        },
        {
            "name": "Tērvetes pag.",
            "code": "CITY_TERVETES_PAG",
            "county": {
                "code": "COUNTY_TERVETES_NOV"
            }
        },
        {
            "name": "Bukaišu pag.",
            "code": "CITY_BUKAISU_PAG",
            "county": {
                "code": "COUNTY_TERVETES_NOV"
            }
        },
        {
            "name": "Augstkalnes pag.",
            "code": "CITY_AUGSTKALNES_PAG",
            "county": {
                "code": "COUNTY_TERVETES_NOV"
            }
        },
        {
            "name": "Zilupe",
            "code": "CITY_ZILUPE",
            "county": {
                "code": "COUNTY_ZILUPES_NOV"
            }
        },
        {
            "name": "Pasienes pag.",
            "code": "CITY_PASIENES_PAG",
            "county": {
                "code": "COUNTY_ZILUPES_NOV"
            }
        },
        {
            "name": "Zaļesjes pag.",
            "code": "CITY_ZALESJES_PAG",
            "county": {
                "code": "COUNTY_ZILUPES_NOV"
            }
        },
        {
            "name": "Lauderu pag.",
            "code": "CITY_LAUDERU_PAG",
            "county": {
                "code": "COUNTY_ZILUPES_NOV"
            }
        },
        {
            "name": "Cenu pag.",
            "code": "CITY_CENU_PAG",
            "county": {
                "code": "COUNTY_OZOLNIEKU_NOV"
            }
        },
        {
            "name": "Salgales pag.",
            "code": "CITY_SALGALES_PAG",
            "county": {
                "code": "COUNTY_OZOLNIEKU_NOV"
            }
        },
        {
            "name": "Ozolnieku pag.",
            "code": "CITY_OZOLNIEKU_PAG",
            "county": {
                "code": "COUNTY_OZOLNIEKU_NOV"
            }
        },
        {
            "name": "Ozolnieki",
            "code": "CITY_OZOLNIEKI",
            "county": {
                "code": "COUNTY_OZOLNIEKU_NOV"
            }
        },
        {
            "name": "Kandava",
            "code": "CITY_KANDAVA",
            "county": {
                "code": "COUNTY_KANDAVAS_NOV"
            }
        },
        {
            "name": "Vānes pag.",
            "code": "CITY_VANES_PAG",
            "county": {
                "code": "COUNTY_KANDAVAS_NOV"
            }
        },
        {
            "name": "Zemītes pag.",
            "code": "CITY_ZEMITES_PAG",
            "county": {
                "code": "COUNTY_KANDAVAS_NOV"
            }
        },
        {
            "name": "Zantes pag.",
            "code": "CITY_ZANTES_PAG",
            "county": {
                "code": "COUNTY_KANDAVAS_NOV"
            }
        },
        {
            "name": "Matkules pag.",
            "code": "CITY_MATKULES_PAG",
            "county": {
                "code": "COUNTY_KANDAVAS_NOV"
            }
        },
        {
            "name": "Kandavas pag.",
            "code": "CITY_KANDAVAS_PAG",
            "county": {
                "code": "COUNTY_KANDAVAS_NOV"
            }
        },
        {
            "name": "Cēres pag.",
            "code": "CITY_CERES_PAG",
            "county": {
                "code": "COUNTY_KANDAVAS_NOV"
            }
        },
        {
            "name": "Sigulda",
            "code": "CITY_SIGULDA",
            "county": {
                "code": "COUNTY_SIGULDAS_NOV"
            }
        },
        {
            "name": "Siguldas pag.",
            "code": "CITY_SIGULDAS_PAG",
            "county": {
                "code": "COUNTY_SIGULDAS_NOV"
            }
        },
        {
            "name": "Allažu pag.",
            "code": "CITY_ALLAZU_PAG",
            "county": {
                "code": "COUNTY_SIGULDAS_NOV"
            }
        },
        {
            "name": "Mores pag.",
            "code": "CITY_MORES_PAG",
            "county": {
                "code": "COUNTY_SIGULDAS_NOV"
            }
        },
        {
            "name": "Peltes",
            "code": "CITY_PELTES",
            "county": {
                "code": "COUNTY_SIGULDAS_NOV"
            }
        },
        {
            "name": "Egļupe",
            "code": "CITY_EGLUPE",
            "county": {
                "code": "COUNTY_SIGULDAS_NOV"
            }
        },
        {
            "name": "Ilūkste",
            "code": "CITY_ILUKSTE",
            "county": {
                "code": "COUNTY_ILUKSTES_NOV"
            }
        },
        {
            "name": "Subate",
            "code": "CITY_SUBATE",
            "county": {
                "code": "COUNTY_ILUKSTES_NOV"
            }
        },
        {
            "name": "Eglaines pag.",
            "code": "CITY_EGLAINES_PAG",
            "county": {
                "code": "COUNTY_ILUKSTES_NOV"
            }
        },
        {
            "name": "Bebrenes pag.",
            "code": "CITY_BEBRENES_PAG",
            "county": {
                "code": "COUNTY_ILUKSTES_NOV"
            }
        },
        {
            "name": "Dvietes pag.",
            "code": "CITY_DVIETES_PAG",
            "county": {
                "code": "COUNTY_ILUKSTES_NOV"
            }
        },
        {
            "name": "Pilskalnes pag.",
            "code": "CITY_PILSKALNES_PAG",
            "county": {
                "code": "COUNTY_ILUKSTES_NOV"
            }
        },
        {
            "name": "Prodes pag.",
            "code": "CITY_PRODES_PAG",
            "county": {
                "code": "COUNTY_ILUKSTES_NOV"
            }
        },
        {
            "name": "Šēderes pag.",
            "code": "CITY_SEDERES_PAG",
            "county": {
                "code": "COUNTY_ILUKSTES_NOV"
            }
        },
        {
            "name": "Ikšķile",
            "code": "CITY_IKSKILE",
            "county": {
                "code": "COUNTY_IKSKILES_NOV"
            }
        },
        {
            "name": "Tīnūžu pag.",
            "code": "CITY_TINUZU_PAG",
            "county": {
                "code": "COUNTY_IKSKILES_NOV"
            }
        },
        {
            "name": "Daugavmala",
            "code": "CITY_DAUGAVMALA",
            "county": {
                "code": "COUNTY_IKSKILES_NOV"
            }
        },
        {
            "name": "Jaunikšķile",
            "code": "CITY_JAUNIKSKILE",
            "county": {
                "code": "COUNTY_IKSKILES_NOV"
            }
        },
        {
            "name": "Ādamlauks",
            "code": "CITY_ADAMLAUKS",
            "county": {
                "code": "COUNTY_IKSKILES_NOV"
            }
        },
        {
            "name": "Kastuļinas pag.",
            "code": "CITY_KASTULINAS_PAG",
            "county": {
                "code": "COUNTY_AGLONAS_NOV"
            }
        },
        {
            "name": "Grāveru pag.",
            "code": "CITY_GRAVERU_PAG",
            "county": {
                "code": "COUNTY_AGLONAS_NOV"
            }
        },
        {
            "name": "Aglonas pag.",
            "code": "CITY_AGLONAS_PAG",
            "county": {
                "code": "COUNTY_AGLONAS_NOV"
            }
        },
        {
            "name": "Šķeltovas pag.",
            "code": "CITY_SKELTOVAS_PAG",
            "county": {
                "code": "COUNTY_AGLONAS_NOV"
            }
        },
        {
            "name": "Aglona",
            "code": "CITY_AGLONA",
            "county": {
                "code": "COUNTY_AGLONAS_NOV"
            }
        },
        {
            "name": "Aizpute",
            "code": "CITY_AIZPUTE",
            "county": {
                "code": "COUNTY_AIZPUTES_NOV"
            }
        },
        {
            "name": "Cīravas pag.",
            "code": "CITY_CIRAVAS_PAG",
            "county": {
                "code": "COUNTY_AIZPUTES_NOV"
            }
        },
        {
            "name": "Aizputes pag.",
            "code": "CITY_AIZPUTES_PAG",
            "county": {
                "code": "COUNTY_AIZPUTES_NOV"
            }
        },
        {
            "name": "Kalvenes pag.",
            "code": "CITY_KALVENES_PAG",
            "county": {
                "code": "COUNTY_AIZPUTES_NOV"
            }
        },
        {
            "name": "Lažas pag.",
            "code": "CITY_LAZAS_PAG",
            "county": {
                "code": "COUNTY_AIZPUTES_NOV"
            }
        },
        {
            "name": "Kazdangas pag.",
            "code": "CITY_KAZDANGAS_PAG",
            "county": {
                "code": "COUNTY_AIZPUTES_NOV"
            }
        },
        {
            "name": "Aknīste",
            "code": "CITY_AKNISTE",
            "county": {
                "code": "COUNTY_AKNISTES_NOV"
            }
        },
        {
            "name": "Aknīstes pag.",
            "code": "CITY_AKNISTES_PAG",
            "county": {
                "code": "COUNTY_AKNISTES_NOV"
            }
        },
        {
            "name": "Gārsenes pag.",
            "code": "CITY_GARSENES_PAG",
            "county": {
                "code": "COUNTY_AKNISTES_NOV"
            }
        },
        {
            "name": "Asares pag.",
            "code": "CITY_ASARES_PAG",
            "county": {
                "code": "COUNTY_AKNISTES_NOV"
            }
        },
        {
            "name": "Staicele",
            "code": "CITY_STAICELE",
            "county": {
                "code": "COUNTY_ALOJAS_NOV"
            }
        },
        {
            "name": "Aloja",
            "code": "CITY_ALOJA",
            "county": {
                "code": "COUNTY_ALOJAS_NOV"
            }
        },
        {
            "name": "Braslavas pag.",
            "code": "CITY_BRASLAVAS_PAG",
            "county": {
                "code": "COUNTY_ALOJAS_NOV"
            }
        },
        {
            "name": "Brīvzemnieku pag.",
            "code": "CITY_BRIVZEMNIEKU_PAG",
            "county": {
                "code": "COUNTY_ALOJAS_NOV"
            }
        },
        {
            "name": "Staiceles pag.",
            "code": "CITY_STAICELES_PAG",
            "county": {
                "code": "COUNTY_ALOJAS_NOV"
            }
        },
        {
            "name": "Alojas pag.",
            "code": "CITY_ALOJAS_PAG",
            "county": {
                "code": "COUNTY_ALOJAS_NOV"
            }
        },
        {
            "name": "Dienvidstacija",
            "code": "CITY_DIENVIDSTACIJA",
            "county": {
                "code": "COUNTY_ALSUNGAS_NOV"
            }
        },
        {
            "name": "Reģi",
            "code": "CITY_REGI",
            "county": {
                "code": "COUNTY_ALSUNGAS_NOV"
            }
        },
        {
            "name": "Grāveri",
            "code": "CITY_GRAVERI",
            "county": {
                "code": "COUNTY_ALSUNGAS_NOV"
            }
        },
        {
            "name": "Bērzkalni",
            "code": "CITY_BERZKALNI",
            "county": {
                "code": "COUNTY_ALSUNGAS_NOV"
            }
        },
        {
            "name": "Ziedlejas",
            "code": "CITY_ZIEDLEJAS",
            "county": {
                "code": "COUNTY_ALSUNGAS_NOV"
            }
        },
        {
            "name": "Būcmaņciems",
            "code": "CITY_BUCMANCIEMS",
            "county": {
                "code": "COUNTY_ALSUNGAS_NOV"
            }
        },
        {
            "name": "Almāle",
            "code": "CITY_ALMALE",
            "county": {
                "code": "COUNTY_ALSUNGAS_NOV"
            }
        },
        {
            "name": "Alsunga",
            "code": "CITY_ALSUNGA",
            "county": {
                "code": "COUNTY_ALSUNGAS_NOV"
            }
        },
        {
            "name": "Balande",
            "code": "CITY_BALANDE",
            "county": {
                "code": "COUNTY_ALSUNGAS_NOV"
            }
        },
        {
            "name": "Alūksne",
            "code": "CITY_ALUKSNE",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Mālupes pag.",
            "code": "CITY_MāLUPES_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Pededzes pag.",
            "code": "CITY_PADEDZES_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Jaunalūksnes pag.",
            "code": "CITY_JAUNALUKSNES_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Ziemera pag.",
            "code": "CITY_ZIEMERA_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Alsviķu pag.",
            "code": "CITY_ALSVIKU_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Jaunannas pag.",
            "code": "CITY_JAUNANNAS_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Liepnas pag.",
            "code": "CITY_LIEPNAS_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Veclaicenes pag.",
            "code": "CITY_VECLAICENES_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Jaunlaicenes pag.",
            "code": "CITY_JAUNLAICENES_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Malienas pag.",
            "code": "CITY_MALIENAS_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Kalncempju pag.",
            "code": "CITY_KALNCEMPJU_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Mārkalnes pag.",
            "code": "CITY_MARKALNES_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Ilzenes pag.",
            "code": "CITY_ILZENES_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Annas pag.",
            "code": "CITY_ANNAS_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Zeltiņu pag.",
            "code": "CITY_ZELTINU_PAG",
            "county": {
                "code": "COUNTY_ALUKSNES_NOV"
            }
        },
        {
            "name": "Ape",
            "code": "CITY_APE",
            "county": {
                "code": "COUNTY_APES_NOV"
            }
        },
        {
            "name": "Gaujienas pag.",
            "code": "CITY_GAUJIENAS_PAG",
            "county": {
                "code": "COUNTY_APES_NOV"
            }
        },
        {
            "name": "Virešu pag.",
            "code": "CITY_VIRESU_PAG",
            "county": {
                "code": "COUNTY_APES_NOV"
            }
        },
        {
            "name": "Trapenes pag.",
            "code": "CITY_TRAPENES_PAG",
            "county": {
                "code": "COUNTY_APES_NOV"
            }
        },
        {
            "name": "Apes pag.",
            "code": "CITY_APES_PAG",
            "county": {
                "code": "COUNTY_APES_NOV"
            }
        },
        {
            "name": "Lubāna",
            "code": "CITY_LUBANA",
            "county": {
                "code": "COUNTY_LUBANAS_NOV"
            }
        },
        {
            "name": "Indrānu pag.",
            "code": "CITY_INDRANU_PAG",
            "county": {
                "code": "COUNTY_LUBANAS_NOV"
            }
        },
        {
            "name": "Ādaži",
            "code": "CITY_ADAZI",
            "county": {
                "code": "COUNTY_ADAZU_NOV"
            }
        },
        {
            "name": "Iļķene",
            "code": "CITY_ILKENE",
            "county": {
                "code": "COUNTY_ADAZU_NOV"
            }
        },
        {
            "name": "Alderi",
            "code": "CITY_ALDERI",
            "county": {
                "code": "COUNTY_ADAZU_NOV"
            }
        },
        {
            "name": "Āņi",
            "code": "CITY_ANI",
            "county": {
                "code": "COUNTY_ADAZU_NOV"
            }
        },
        {
            "name": "Garkalne",
            "code": "CITY_GARKALNE",
            "county": {
                "code": "COUNTY_ADAZU_NOV"
            }
        },
        {
            "name": "Stapriņi",
            "code": "CITY_STAPRINI",
            "county": {
                "code": "COUNTY_ADAZU_NOV"
            }
        },
        {
            "name": "Atari",
            "code": "CITY_ATARI",
            "county": {
                "code": "COUNTY_ADAZU_NOV"
            }
        },
        {
            "name": "Divezeri",
            "code": "CITY_DIVEZERI",
            "county": {
                "code": "COUNTY_ADAZU_NOV"
            }
        },
        {
            "name": "Birznieki",
            "code": "CITY_BIRZNIEKI",
            "county": {
                "code": "COUNTY_ADAZU_NOV"
            }
        },
        {
            "name": "Eimuri",
            "code": "CITY_EIMURI",
            "county": {
                "code": "COUNTY_ADAZU_NOV"
            }
        },
        {
            "name": "Kadaga",
            "code": "CITY_KADAGA",
            "county": {
                "code": "COUNTY_ADAZU_NOV"
            }
        },
        {
            "name": "Baltezers",
            "code": "CITY_BALTEZERS",
            "county": {
                "code": "COUNTY_ADAZU_NOV"
            }
        },
        {
            "name": "Carnikava",
            "code": "CITY_CARNIKAVA",
            "county": {
                "code": "COUNTY_CARNIKAVAS_NOV"
            }
        },
        {
            "name": "Gauja",
            "code": "CITY_GAUJA",
            "county": {
                "code": "COUNTY_CARNIKAVAS_NOV"
            }
        },
        {
            "name": "Garciems",
            "code": "CITY_GARCIEMS",
            "county": {
                "code": "COUNTY_CARNIKAVAS_NOV"
            }
        },
        {
            "name": "Siguļi",
            "code": "CITY_SIGULI",
            "county": {
                "code": "COUNTY_CARNIKAVAS_NOV"
            }
        },
        {
            "name": "Eimuri",
            "code": "CITY_EIMURI",
            "county": {
                "code": "COUNTY_CARNIKAVAS_NOV"
            }
        },
        {
            "name": "Mežciems",
            "code": "CITY_MEZCIEMS_CARNIKAVAS",
            "county": {
                "code": "COUNTY_CARNIKAVAS_NOV"
            }
        },
        {
            "name": "Laveri",
            "code": "CITY_LAVERI",
            "county": {
                "code": "COUNTY_CARNIKAVAS_NOV"
            }
        },
        {
            "name": "Lilaste",
            "code": "CITY_LILASTE",
            "county": {
                "code": "COUNTY_CARNIKAVAS_NOV"
            }
        },
        {
            "name": "Garupe",
            "code": "CITY_GARUPE",
            "county": {
                "code": "COUNTY_CARNIKAVAS_NOV"
            }
        },
        {
            "name": "Mežgarciems",
            "code": "CITY_MEZGARCIEMS",
            "county": {
                "code": "COUNTY_CARNIKAVAS_NOV"
            }
        },
        {
            "name": "Kalngale",
            "code": "CITY_KALNGALE",
            "county": {
                "code": "COUNTY_CARNIKAVAS_NOV"
            }
        },
        {
            "name": "Baltezers",
            "code": "CITY_BALTEZERS_GARKALNES",
            "county": {
                "code": "COUNTY_GARKALNES_NOV"
            }
        },
        {
            "name": "Berģi",
            "code": "CITY_BERGI_GARKALNES",
            "county": {
                "code": "COUNTY_GARKALNES_NOV"
            }
        },
        {
            "name": "Upesciems",
            "code": "CITY_UPESCIEMS",
            "county": {
                "code": "COUNTY_GARKALNES_NOV"
            }
        },
        {
            "name": "Amatnieki",
            "code": "CITY_AMATNIEKI",
            "county": {
                "code": "COUNTY_GARKALNES_NOV"
            }
        },
        {
            "name": "Priedkalne",
            "code": "CITY_PRIEDKALNE",
            "county": {
                "code": "COUNTY_GARKALNES_NOV"
            }
        },
        {
            "name": "Garkalne",
            "code": "CITY_GARKALNE_GARKALNES",
            "county": {
                "code": "COUNTY_GARKALNES_NOV"
            }
        },
        {
            "name": "Suži",
            "code": "CITY_SUZI",
            "county": {
                "code": "COUNTY_GARKALNES_NOV"
            }
        },
        {
            "name": "Skuķīši",
            "code": "CITY_SKUKISI",
            "county": {
                "code": "COUNTY_GARKALNES_NOV"
            }
        },
        {
            "name": "Makstenieki",
            "code": "CITY_MAKSTENIEKI",
            "county": {
                "code": "COUNTY_GARKALNES_NOV"
            }
        },
        {
            "name": "Priežlejas",
            "code": "CITY_PRIEZLEJAS",
            "county": {
                "code": "COUNTY_GARKALNES_NOV"
            }
        },
        {
            "name": "Sunīši",
            "code": "CITY_SUNISI",
            "county": {
                "code": "COUNTY_GARKALNES_NOV"
            }
        },
        {
            "name": "Langstiņi",
            "code": "CITY_LANGSTINI",
            "county": {
                "code": "COUNTY_GARKALNES_NOV"
            }
        },
        {
            "name": "Bukulti",
            "code": "CITY_BUKULTI_GARKALNES",
            "county": {
                "code": "COUNTY_GARKALNES_NOV"
            }
        },
        {
            "name": "Roja",
            "code": "CITY_ROJA",
            "county": {
                "code": "COUNTY_ROJAS_NOV"
            }
        },
        {
            "name": "Melnsils",
            "code": "CITY_MELNSILS",
            "county": {
                "code": "COUNTY_ROJAS_NOV"
            }
        },
        {
            "name": "Žocene",
            "code": "CITY_ZOCENE",
            "county": {
                "code": "COUNTY_ROJAS_NOV"
            }
        },
        {
            "name": "Aizklāņi",
            "code": "CITY_AIZKLANI",
            "county": {
                "code": "COUNTY_ROJAS_NOV"
            }
        },
        {
            "name": "Valgalciems",
            "code": "CITY_VALGALCIEMS",
            "county": {
                "code": "COUNTY_ROJAS_NOV"
            }
        },
        {
            "name": "Pūrciems",
            "code": "CITY_PURCIEMS",
            "county": {
                "code": "COUNTY_ROJAS_NOV"
            }
        },
        {
            "name": "Kaltene",
            "code": "CITY_KALTENE",
            "county": {
                "code": "COUNTY_ROJAS_NOV"
            }
        },
        {
            "name": "Apšuciems",
            "code": "CITY_APSUCIEMS",
            "county": {
                "code": "COUNTY_ROJAS_NOV"
            }
        },
        {
            "name": "Rude",
            "code": "CITY_RUDE",
            "county": {
                "code": "COUNTY_ROJAS_NOV"
            }
        },
        {
            "name": "Ģipka",
            "code": "CITY_GIPKA",
            "county": {
                "code": "COUNTY_ROJAS_NOV"
            }
        },
        {
            "name": "Upesgrīva",
            "code": "CITY_UPESGRIVA",
            "county": {
                "code": "COUNTY_MERSRAGA_NOV"
            }
        },
        {
            "name": "Alksnāji",
            "code": "CITY_ALKSNAJI",
            "county": {
                "code": "COUNTY_MERSRAGA_NOV"
            }
        },
        {
            "name": "Ķipati",
            "code": "CITY_KIPATI",
            "county": {
                "code": "COUNTY_MERSRAGA_NOV"
            }
        },
        {
            "name": "Mērsrags",
            "code": "CITY_MERSRAGS",
            "county": {
                "code": "COUNTY_MERSRAGA_NOV"
            }
        },
        {
            "name": "Baldone",
            "code": "CITY_BALDONE",
            "county": {
                "code": "COUNTY_BALDONES_NOV"
            }
        },
        {
            "name": "Baldones pag.",
            "code": "CITY_BALDONES_PAG",
            "county": {
                "code": "COUNTY_BALDONES_NOV"
            }
        },
        {
            "name": "Avoti",
            "code": "CITY_AVOTI",
            "county": {
                "code": "COUNTY_BALDONES_NOV"
            }
        },
        {
            "name": "Auce",
            "code": "CITY_AUCE",
            "county": {
                "code": "COUNTY_AUCES_NOV"
            }
        },
        {
            "name": "Bēnes pag.",
            "code": "CITY_BENES_PAG",
            "county": {
                "code": "COUNTY_AUCES_NOV"
            }
        },
        {
            "name": "Īles pag.",
            "code": "CITY_ILES_PAG",
            "county": {
                "code": "COUNTY_AUCES_NOV"
            }
        },
        {
            "name": "Vītiņu pag.",
            "code": "CITY_VITINU_PAG",
            "county": {
                "code": "COUNTY_AUCES_NOV"
            }
        },
        {
            "name": "Vecauces pag.",
            "code": "CITY_VECAUCES_PAG",
            "county": {
                "code": "COUNTY_AUCES_NOV"
            }
        },
        {
            "name": "Lielauces pag.",
            "code": "CITY_LIELAUCES_PAG",
            "county": {
                "code": "COUNTY_AUCES_NOV"
            }
        },
        {
            "name": "Ukru pag.",
            "code": "CITY_UKRU_PAG",
            "county": {
                "code": "COUNTY_AUCES_NOV"
            }
        },
        {
            "name": "Bēne",
            "code": "CITY_BENE",
            "county": {
                "code": "COUNTY_AUCES_NOV"
            }
        },
        {
            "name": "Babītes pag.",
            "code": "CITY_BABITES_PAG",
            "county": {
                "code": "COUNTY_BABITES_NOV"
            }
        },
        {
            "name": "Babīte",
            "code": "CITY_BABITE",
            "county": {
                "code": "COUNTY_BABITES_NOV"
            }
        },
        {
            "name": "Brīvkalni",
            "code": "CITY_BRIVKALNI",
            "county": {
                "code": "COUNTY_BABITES_NOV"
            }
        },
        {
            "name": "Salas pag.",
            "code": "CITY_SALAS_PAG",
            "county": {
                "code": "COUNTY_BABITES_NOV"
            }
        },
        {
            "name": "Piņķi",
            "code": "CITY_PINKI",
            "county": {
                "code": "COUNTY_BABITES_NOV"
            }
        },
        {
            "name": "Mežāres",
            "code": "CITY_MEZARES",
            "county": {
                "code": "COUNTY_BABITES_NOV"
            }
        },
        {
            "name": "Spilve",
            "code": "CITY_SPILVE",
            "county": {
                "code": "COUNTY_BABITES_NOV"
            }
        },
        {
            "name": "Baltinava",
            "code": "CITY_BALTINAVA",
            "county": {
                "code": "COUNTY_BALTINAVAS_NOV"
            }
        },
        {
            "name": "Balvi",
            "code": "CITY_BALVI",
            "county": {
                "code": "COUNTY_BALVU_NOV"
            }
        },
        {
            "name": "Bērzpils pag.",
            "code": "CITY_BERZPILS_PAG",
            "county": {
                "code": "COUNTY_BALVU_NOV"
            }
        },
        {
            "name": "Lazdulejas pag.",
            "code": "CITY_LAZDULEJAS_PAG",
            "county": {
                "code": "COUNTY_BALVU_NOV"
            }
        },
        {
            "name": "Vīksnas pag.",
            "code": "CITY_VIKSNAS_PAG",
            "county": {
                "code": "COUNTY_BALVU_NOV"
            }
        },
        {
            "name": "Kubulu pag.",
            "code": "CITY_KUBULU_PAG",
            "county": {
                "code": "COUNTY_BALVU_NOV"
            }
        },
        {
            "name": "Krišjāņu pag.",
            "code": "CITY_KRISJANU_PAG",
            "county": {
                "code": "COUNTY_BALVU_NOV"
            }
        },
        {
            "name": "Balvu pag.",
            "code": "CITY_BALVU_PAG",
            "county": {
                "code": "COUNTY_BALVU_NOV"
            }
        },
        {
            "name": "Vectilžas pag.",
            "code": "CITY_VECTILZAS_PAG",
            "county": {
                "code": "COUNTY_BALVU_NOV"
            }
        },
        {
            "name": "Tilžas pag.",
            "code": "CITY_TILZAS_PAG",
            "county": {
                "code": "COUNTY_BALVU_NOV"
            }
        },
        {
            "name": "Briežuciema pag.",
            "code": "CITY_BRIEZUCIEMA_PAG",
            "county": {
                "code": "COUNTY_BALVU_NOV"
            }
        },
        {
            "name": "Bērzkalnes pag.",
            "code": "CITY_BERZKALNES_PAG",
            "county": {
                "code": "COUNTY_BALVU_NOV"
            }
        },
        {
            "name": "Bauska",
            "code": "CITY_BAUSKA",
            "county": {
                "code": "COUNTY_BAUSKAS_NOV"
            }
        },
        {
            "name": "Ceraukstes pag.",
            "code": "CITY_CERAUKSTES_PAG",
            "county": {
                "code": "COUNTY_BAUSKAS_NOV"
            }
        },
        {
            "name": "Dāviņu pag.",
            "code": "CITY_DAVINU_PAG",
            "county": {
                "code": "COUNTY_BAUSKAS_NOV"
            }
        },
        {
            "name": "Codes pag.",
            "code": "CITY_CODES_PAG",
            "county": {
                "code": "COUNTY_BAUSKAS_NOV"
            }
        },
        {
            "name": "Īslīces pag.",
            "code": "CITY_ISLICES_PAG",
            "county": {
                "code": "COUNTY_BAUSKAS_NOV"
            }
        },
        {
            "name": "Vecsaules pag.",
            "code": "CITY_VECSAULES_PAG",
            "county": {
                "code": "COUNTY_BAUSKAS_NOV"
            }
        },
        {
            "name": "Gailīšu pag.",
            "code": "CITY_GAILISU_PAG",
            "county": {
                "code": "COUNTY_BAUSKAS_NOV"
            }
        },
        {
            "name": "Brunavas pag.",
            "code": "CITY_BRUNAVAS_PAG",
            "county": {
                "code": "COUNTY_BAUSKAS_NOV"
            }
        },
        {
            "name": "Mežotnes pag.",
            "code": "CITY_MEZOTNES_PAG",
            "county": {
                "code": "COUNTY_BAUSKAS_NOV"
            }
        },
        {
            "name": "Trikātas pag.",
            "code": "CITY_TRIKATAS_PAG",
            "county": {
                "code": "COUNTY_BEVERINAS_NOV"
            }
        },
        {
            "name": "Kauguru pag.",
            "code": "CITY_KAUGURU_PAG",
            "county": {
                "code": "COUNTY_BEVERINAS_NOV"
            }
        },
        {
            "name": "Brenguļu pag.",
            "code": "CITY_BRENGULU_PAG",
            "county": {
                "code": "COUNTY_BEVERINAS_NOV"
            }
        },
        {
            "name": "Cēsis",
            "code": "CITY_CESIS",
            "county": {
                "code": "COUNTY_CESU_NOV"
            }
        },
        {
            "name": "Skujenes pag.",
            "code": "CITY_SKUJENES_PAG",
            "county": {
                "code": "COUNTY_CESU_NOV"
            }
        },
        {
            "name": "Vaives pag.",
            "code": "CITY_VAIVES_PAG",
            "county": {
                "code": "COUNTY_CESU_NOV"
            }
        },
        {
            "name": "Cesvaine",
            "code": "CITY_CESVAINE",
            "county": {
                "code": "COUNTY_CESVAINES_NOV"
            }
        },
        {
            "name": "Cesvaines pag.",
            "code": "CITY_CESVAINES_PAG",
            "county": {
                "code": "COUNTY_CESVAINES_NOV"
            }
        },
        {
            "name": "Dagda",
            "code": "CITY_DAGDA",
            "county": {
                "code": "COUNTY_DAGDAS_NOV"
            }
        },
        {
            "name": "Asūnes pag.",
            "code": "CITY_ASUNES_PAG",
            "county": {
                "code": "COUNTY_DAGDAS_NOV"
            }
        },
        {
            "name": "Ezernieku pag.",
            "code": "CITY_EZERNIEKU_PAG",
            "county": {
                "code": "COUNTY_DAGDAS_NOV"
            }
        },
        {
            "name": "Ķepovas pag.",
            "code": "CITY_KEPOVAS_PAG",
            "county": {
                "code": "COUNTY_DAGDAS_NOV"
            }
        },
        {
            "name": "Šķaunes pag.",
            "code": "CITY_SKAUNES_PAG",
            "county": {
                "code": "COUNTY_DAGDAS_NOV"
            }
        },
        {
            "name": "Bērziņu pag.",
            "code": "CITY_BERZINU_PAG",
            "county": {
                "code": "COUNTY_DAGDAS_NOV"
            }
        },
        {
            "name": "Andrupenes pag.",
            "code": "CITY_ANDRUPENES_PAG",
            "county": {
                "code": "COUNTY_DAGDAS_NOV"
            }
        },
        {
            "name": "Konstantinovas pag.",
            "code": "CITY_KONSTANTINOVAS_PAG",
            "county": {
                "code": "COUNTY_DAGDAS_NOV"
            }
        },
        {
            "name": "Dagdas pag.",
            "code": "CITY_DAGDAS_PAG",
            "county": {
                "code": "COUNTY_DAGDAS_NOV"
            }
        },
        {
            "name": "Svariņu pag.",
            "code": "CITY_SVARINU_PAG",
            "county": {
                "code": "COUNTY_DAGDAS_NOV"
            }
        },
        {
            "name": "Andzeļu pag.",
            "code": "CITY_ANDZELU_PAG",
            "county": {
                "code": "COUNTY_DAGDAS_NOV"
            }
        },
        {
            "name": "Medumu pag.",
            "code": "CITY_MEDUMU_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Vaboles pag.",
            "code": "CITY_VABOLES_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Kalupes pag.",
            "code": "CITY_KALUPES_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Dubnas pag.",
            "code": "CITY_DUBNAS_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Līksnas pag.",
            "code": "CITY_LIKSNAS_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Biķernieku pag.",
            "code": "CITY_BIKERNIEKU_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Kalkūnes pag.",
            "code": "CITY_KALKUNES_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Maļinovas pag.",
            "code": "CITY_MALINOVAS_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Laucesas pag.",
            "code": "CITY_LAUCESAS_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Nīcgales pag.",
            "code": "CITY_NICGALES_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Demenes pag.",
            "code": "CITY_DEMENES_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Naujenes pag.",
            "code": "CITY_NAUJENES_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Sventes pag.",
            "code": "CITY_SVENTES_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Tabores pag.",
            "code": "CITY_TABORES_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Ambeļu pag.",
            "code": "CITY_AMBELU_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Višķu pag.",
            "code": "CITY_VISKU_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Skrudalienas pag.",
            "code": "CITY_SKRUDALIENAS_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Salienas pag.",
            "code": "CITY_SALIENAS_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Vecsalienas pag.",
            "code": "CITY_VECSALIENAS_PAG",
            "county": {
                "code": "COUNTY_DAUGAVPILS_NOV"
            }
        },
        {
            "name": "Dobele",
            "code": "CITY_DOBELE",
            "county": {
                "code": "COUNTY_DOBELES_NOV"
            }
        },
        {
            "name": "Jaunbērzes pag.",
            "code": "CITY_JAUNBERZES_PAG",
            "county": {
                "code": "COUNTY_DOBELES_NOV"
            }
        },
        {
            "name": "Penkules pag.",
            "code": "CITY_PENKULES_PAG",
            "county": {
                "code": "COUNTY_DOBELES_NOV"
            }
        },
        {
            "name": "Naudītes pag.",
            "code": "CITY_NAUDITES_PAG",
            "county": {
                "code": "COUNTY_DOBELES_NOV"
            }
        },
        {
            "name": "Annenieku pag.",
            "code": "CITY_ANNENIEKU_PAG",
            "county": {
                "code": "COUNTY_DOBELES_NOV"
            }
        },
        {
            "name": "Bērzes pag.",
            "code": "CITY_BERZES_PAG",
            "county": {
                "code": "COUNTY_DOBELES_NOV"
            }
        },
        {
            "name": "Auru pag.",
            "code": "CITY_AURU_PAG",
            "county": {
                "code": "COUNTY_DOBELES_NOV"
            }
        },
        {
            "name": "Dobeles pag.",
            "code": "CITY_DOBELES_PAG",
            "county": {
                "code": "COUNTY_DOBELES_NOV"
            }
        },
        {
            "name": "Krimūnu pag.",
            "code": "CITY_KRIMUNU_PAG",
            "county": {
                "code": "COUNTY_DOBELES_NOV"
            }
        },
        {
            "name": "Bikstu pag.",
            "code": "CITY_BIKSTU_PAG",
            "county": {
                "code": "COUNTY_DOBELES_NOV"
            }
        },
        {
            "name": "Zebrenes pag.",
            "code": "CITY_ZEBRENES_PAG",
            "county": {
                "code": "COUNTY_DOBELES_NOV"
            }
        },
        {
            "name": "Dundagas pag.",
            "code": "CITY_DUNDAGAS_PAG",
            "county": {
                "code": "COUNTY_DUNDAGAS_NOV"
            }
        },
        {
            "name": "Kolkas pag.",
            "code": "CITY_KOLKAS_PAG",
            "county": {
                "code": "COUNTY_DUNDAGAS_NOV"
            }
        },
        {
            "name": "Dundaga",
            "code": "CITY_DUNDAGA",
            "county": {
                "code": "COUNTY_DUNDAGAS_NOV"
            }
        },
        {
            "name": "Engures pag.",
            "code": "CITY_ENGURES_PAG",
            "county": {
                "code": "COUNTY_ENGURES_NOV"
            }
        },
        {
            "name": "Lapmežciema pag.",
            "code": "CITY_LAPMEZCIEMA_PAG",
            "county": {
                "code": "COUNTY_ENGURES_NOV"
            }
        },
        {
            "name": "Smārdes pag.",
            "code": "CITY_SMARDES_PAG",
            "county": {
                "code": "COUNTY_ENGURES_NOV"
            }
        },
        {
            "name": "Plieņciems",
            "code": "CITY_PLIENCIEMS",
            "county": {
                "code": "COUNTY_ENGURES_NOV"
            }
        },
        {
            "name": "Engure",
            "code": "CITY_ENGURE",
            "county": {
                "code": "COUNTY_ENGURES_NOV"
            }
        },
        {
            "name": "Lapmežciems",
            "code": "CITY_LAPMEZCIEMS",
            "county": {
                "code": "COUNTY_ENGURES_NOV"
            }
        },
        {
            "name": "Ragaciems",
            "code": "CITY_RAGACIEMS",
            "county": {
                "code": "COUNTY_ENGURES_NOV"
            }
        },
        {
            "name": "Smārde",
            "code": "CITY_SMARDE",
            "county": {
                "code": "COUNTY_ENGURES_NOV"
            }
        },
        {
            "name": "Grobiņa",
            "code": "CITY_GROBINA",
            "county": {
                "code": "COUNTY_GROBINAS_NOV"
            }
        },
        {
            "name": "Bārtas pag.",
            "code": "CITY_BARTAS_PAG",
            "county": {
                "code": "COUNTY_GROBINAS_NOV"
            }
        },
        {
            "name": "Grobiņas pag.",
            "code": "CITY_GROBINAS_PAG",
            "county": {
                "code": "COUNTY_GROBINAS_NOV"
            }
        },
        {
            "name": "Gaviezes pag.",
            "code": "CITY_GAVIEZES_PAG",
            "county": {
                "code": "COUNTY_GROBINAS_NOV"
            }
        },
        {
            "name": "Medzes pag.",
            "code": "CITY_MEDZES_PAG",
            "county": {
                "code": "COUNTY_GROBINAS_NOV"
            }
        },
        {
            "name": "Šķēde",
            "code": "CITY_SKEDE",
            "county": {
                "code": "COUNTY_GROBINAS_NOV"
            }
        },
        {
            "name": "Gulbene",
            "code": "CITY_GULBENE",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Rankas pag.",
            "code": "CITY_RANKAS_PAG",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Druvienas pag.",
            "code": "CITY_DRUVIENAS_PAG",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Stāmerienas pag.",
            "code": "CITY_STAMERIENAS_PAG",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Litenes pag.",
            "code": "CITY_LITENES_PAG",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Lizuma pag.",
            "code": "CITY_LIZUMA_PAG",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Jaungulbenes pag.",
            "code": "CITY_JAUNGULBENES_PAG",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Stradu pag.",
            "code": "CITY_STRADU_PAG",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Galgauskas pag.",
            "code": "CITY_GALGAUSKAS_PAG",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Daukstu pag.",
            "code": "CITY_DAUKSTU_PAG",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Tirzas pag.",
            "code": "CITY_TIRZAS_PAG",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Līgo pag.",
            "code": "CITY_LIGO_PAG",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Beļavas pag.",
            "code": "CITY_BELAVAS_PAG",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Lejasciema pag.",
            "code": "CITY_LEJASCIEMA_PAG",
            "county": {
                "code": "COUNTY_GULBENES_NOV"
            }
        },
        {
            "name": "Jaunjelgava",
            "code": "CITY_JAUNJELGAVA",
            "county": {
                "code": "COUNTY_JAUNJELGAVAS_NOV"
            }
        },
        {
            "name": "Jaunjelgavas pag.",
            "code": "CITY_JAUNJELGAVAS_PAG",
            "county": {
                "code": "COUNTY_JAUNJELGAVAS_NOV"
            }
        },
        {
            "name": "Sērenes pag.",
            "code": "CITY_SERENES_PAG",
            "county": {
                "code": "COUNTY_JAUNJELGAVAS_NOV"
            }
        },
        {
            "name": "Daudzeses pag.",
            "code": "CITY_DAUDZESES_PAG",
            "county": {
                "code": "COUNTY_JAUNJELGAVAS_NOV"
            }
        },
        {
            "name": "Sunākstes pag.",
            "code": "CITY_SUNAKSTES_PAG",
            "county": {
                "code": "COUNTY_JAUNJELGAVAS_NOV"
            }
        },
        {
            "name": "Seces pag.",
            "code": "CITY_SECES_PAG",
            "county": {
                "code": "COUNTY_JAUNJELGAVAS_NOV"
            }
        },
        {
            "name": "Staburaga pag.",
            "code": "CITY_STABURAGA_PAG",
            "county": {
                "code": "COUNTY_JAUNJELGAVAS_NOV"
            }
        },
        {
            "name": "Jaunpils pag.",
            "code": "CITY_JAUNPILS_PAG",
            "county": {
                "code": "COUNTY_JAUNPILS_NOV"
            }
        },
        {
            "name": "Viesatu pag.",
            "code": "CITY_VIESATU_PAG",
            "county": {
                "code": "COUNTY_JAUNPILS_NOV"
            }
        },
        {
            "name": "Leimaņu pag.",
            "code": "CITY_LEIMANU_PAG",
            "county": {
                "code": "COUNTY_JEKABPILS_NOV"
            }
        },
        {
            "name": "Rubenes pag.",
            "code": "CITY_RUBENES_PAG",
            "county": {
                "code": "COUNTY_JEKABPILS_NOV"
            }
        },
        {
            "name": "Dignājas pag.",
            "code": "CITY_DIGNAJAS_PAG",
            "county": {
                "code": "COUNTY_JEKABPILS_NOV"
            }
        },
        {
            "name": "Kalna pag.",
            "code": "CITY_KALNA_PAG",
            "county": {
                "code": "COUNTY_JEKABPILS_NOV"
            }
        },
        {
            "name": "Zasas pag.",
            "code": "CITY_ZASAS_PAG",
            "county": {
                "code": "COUNTY_JEKABPILS_NOV"
            }
        },
        {
            "name": "Ābeļu pag.",
            "code": "CITY_ABELU_PAG",
            "county": {
                "code": "COUNTY_JEKABPILS_NOV"
            }
        },
        {
            "name": "Dunavas pag.",
            "code": "CITY_DUNAVAS_PAG",
            "county": {
                "code": "COUNTY_JEKABPILS_NOV"
            }
        },
        {
            "name": "Glūdas pag.",
            "code": "CITY_GLUDAS_PAG",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Līvbērzes pag.",
            "code": "CITY_LIVBERZES_PAG",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Sesavas pag.",
            "code": "CITY_SESAVAS_PAG",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Valgundes pag.",
            "code": "CITY_VALGUNDES_PAG",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Jaunsvirlaukas pag.",
            "code": "CITY_JAUNSVIRLAUKAS_PAG",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Svētes pag.",
            "code": "CITY_SVETES_PAG",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Kalnciema pag.",
            "code": "CITY_KALNCIEMA_PAG",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Lielplatones pag.",
            "code": "CITY_LIELPLATONES_PAG",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Platones pag.",
            "code": "CITY_PLATONES_PAG",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Vilces pag.",
            "code": "CITY_VILCES_PAG",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Elejas pag.",
            "code": "CITY_ELEJAS_PAG",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Zaļenieku pag.",
            "code": "CITY_ZALENIEKU_PAG",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Vircavas pag.",
            "code": "CITY_VIRCAVAS_PAG",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Eleja",
            "code": "CITY_ELEJA",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Atpūta",
            "code": "CITY_ATPUTA",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Tušķi",
            "code": "CITY_TUSKI",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Vītoliņi",
            "code": "CITY_VITOLINI",
            "county": {
                "code": "COUNTY_JELGAVAS_NOV"
            }
        },
        {
            "name": "Kārsava",
            "code": "CITY_KARSAVA",
            "county": {
                "code": "COUNTY_KARSAVAS_NOV"
            }
        },
        {
            "name": "Mērdzenes pag.",
            "code": "CITY_MERDZENES_PAG",
            "county": {
                "code": "COUNTY_KARSAVAS_NOV"
            }
        },
        {
            "name": "Salnavas pag.",
            "code": "CITY_SALNAVAS_PAG",
            "county": {
                "code": "COUNTY_KARSAVAS_NOV"
            }
        },
        {
            "name": "Mežvidu pag.",
            "code": "CITY_MEZVIDU_PAG",
            "county": {
                "code": "COUNTY_KARSAVAS_NOV"
            }
        },
        {
            "name": "Malnavas pag.",
            "code": "CITY_MALNAVAS_PAG",
            "county": {
                "code": "COUNTY_KARSAVAS_NOV"
            }
        },
        {
            "name": "Goliševas pag.",
            "code": "CITY_GOLISEVAS_PAG",
            "county": {
                "code": "COUNTY_KARSAVAS_NOV"
            }
        },
        {
            "name": "Bebru pag.",
            "code": "CITY_BEBRU_PAG",
            "county": {
                "code": "COUNTY_KOKNESES_NOV"
            }
        },
        {
            "name": "Iršu pag.",
            "code": "CITY_IRSU_PAG",
            "county": {
                "code": "COUNTY_KOKNESES_NOV"
            }
        },
        {
            "name": "Kokneses pag.",
            "code": "CITY_KOKNESES_PAG",
            "county": {
                "code": "COUNTY_KOKNESES_NOV"
            }
        },
        {
            "name": "Koknese",
            "code": "CITY_KOKNESE",
            "county": {
                "code": "COUNTY_KOKNESES_NOV"
            }
        },
        {
            "name": "Krimuldas pag.",
            "code": "CITY_KRIMULDAS_PAG",
            "county": {
                "code": "COUNTY_KRIMULDAS_NOV"
            }
        },
        {
            "name": "Lēdurgas pag.",
            "code": "CITY_LEDURGAS_PAG",
            "county": {
                "code": "COUNTY_KRIMULDAS_NOV"
            }
        },
        {
            "name": "Vīpes pag.",
            "code": "CITY_VIPES_PAG",
            "county": {
                "code": "COUNTY_KRUSTPILS_NOV"
            }
        },
        {
            "name": "Kūku pag.",
            "code": "CITY_KUKU_PAG",
            "county": {
                "code": "COUNTY_KRUSTPILS_NOV"
            }
        },
        {
            "name": "Variešu pag.",
            "code": "CITY_VARIESU_PAG",
            "county": {
                "code": "COUNTY_KRUSTPILS_NOV"
            }
        },
        {
            "name": "Mežāres pag.",
            "code": "CITY_MEZARES_PAG",
            "county": {
                "code": "COUNTY_KRUSTPILS_NOV"
            }
        },
        {
            "name": "Krustpils pag.",
            "code": "CITY_KRUSTPILS_PAG",
            "county": {
                "code": "COUNTY_KRUSTPILS_NOV"
            }
        },
        {
            "name": "Atašienes pag.",
            "code": "CITY_ATASIENES_PAG",
            "county": {
                "code": "COUNTY_KRUSTPILS_NOV"
            }
        },
        {
            "name": "Kuldīga",
            "code": "CITY_KULDIGA",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Rumbas pag.",
            "code": "CITY_RUMBAS_PAG",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Snēpeles pag.",
            "code": "CITY_SNEPELES_PAG",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Gudenieku pag.",
            "code": "CITY_GUDENIEKU_PAG",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Ēdoles pag.",
            "code": "CITY_EDOLES_PAG",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Laidu pag.",
            "code": "CITY_LAIDU_PAG",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Pelču pag.",
            "code": "CITY_PELCU_PAG",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Padures pag.",
            "code": "CITY_PADURES_PAG",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Kurmāles pag.",
            "code": "CITY_KURMALES_PAG",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Vārmes pag.",
            "code": "CITY_VARMES_PAG",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Kabiles pag.",
            "code": "CITY_KABILES_PAG",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Īvandes pag.",
            "code": "CITY_IVANDES_PAG",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Rendas pag.",
            "code": "CITY_RENDAS_PAG",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Turlavas pag.",
            "code": "CITY_TURLAVAS_PAG",
            "county": {
                "code": "COUNTY_KULDIGAS_NOV"
            }
        },
        {
            "name": "Baloži",
            "code": "CITY_BALOZI",
            "county": {
                "code": "COUNTY_KEKAVAS_NOV"
            }
        },
        {
            "name": "Valdlauči",
            "code": "CITY_VALDLAUCI",
            "county": {
                "code": "COUNTY_KEKAVAS_NOV"
            }
        },
        {
            "name": "Ķekavas pag.",
            "code": "CITY_KEKAVAS_PAG",
            "county": {
                "code": "COUNTY_KEKAVAS_NOV"
            }
        },
        {
            "name": "Daugmales pag.",
            "code": "CITY_DAUGMALES_PAG",
            "county": {
                "code": "COUNTY_KEKAVAS_NOV"
            }
        },
        {
            "name": "Dzērumi",
            "code": "CITY_DZERUMI",
            "county": {
                "code": "COUNTY_KEKAVAS_NOV"
            }
        },
        {
            "name": "Ķekava",
            "code": "CITY_KEKAVA",
            "county": {
                "code": "COUNTY_KEKAVAS_NOV"
            }
        },
        {
            "name": "Rāmava",
            "code": "CITY_RAMAVA",
            "county": {
                "code": "COUNTY_KEKAVAS_NOV"
            }
        },
        {
            "name": "Krogsils",
            "code": "CITY_KROGSILS",
            "county": {
                "code": "COUNTY_KEKAVAS_NOV"
            }
        },
        {
            "name": "Odukalns",
            "code": "CITY_ODUKALNS",
            "county": {
                "code": "COUNTY_KEKAVAS_NOV"
            }
        },
        {
            "name": "Līgatne",
            "code": "CITY_LIGATNE",
            "county": {
                "code": "COUNTY_LIGATNES_NOV"
            }
        },
        {
            "name": "Līgatnes pag.",
            "code": "CITY_LIGATNES_PAG",
            "county": {
                "code": "COUNTY_LIGATNES_NOV"
            }
        },
        {
            "name": "Limbaži",
            "code": "CITY_LIMBAZI",
            "county": {
                "code": "COUNTY_LIMBAZU_NOV"
            }
        },
        {
            "name": "Limbažu pag.",
            "code": "CITY_LIMBAZU_PAG",
            "county": {
                "code": "COUNTY_LIMBAZU_NOV"
            }
        },
        {
            "name": "Vidrižu pag.",
            "code": "CITY_VIDRIZU_PAG",
            "county": {
                "code": "COUNTY_LIMBAZU_NOV"
            }
        },
        {
            "name": "Katvaru pag.",
            "code": "CITY_KATVARU_PAG",
            "county": {
                "code": "COUNTY_LIMBAZU_NOV"
            }
        },
        {
            "name": "Umurgas pag.",
            "code": "CITY_UMURGAS_PAG",
            "county": {
                "code": "COUNTY_LIMBAZU_NOV"
            }
        },
        {
            "name": "Skultes pag.",
            "code": "CITY_SKULTES_PAG",
            "county": {
                "code": "COUNTY_LIMBAZU_NOV"
            }
        },
        {
            "name": "Pāles pag.",
            "code": "CITY_PALES_PAG",
            "county": {
                "code": "COUNTY_LIMBAZU_NOV"
            }
        },
        {
            "name": "Viļķenes pag.",
            "code": "CITY_VILKENES_PAG",
            "county": {
                "code": "COUNTY_LIMBAZU_NOV"
            }
        },
        {
            "name": "Ziemeļblāzma",
            "code": "CITY_ZIEMELBLAZMA",
            "county": {
                "code": "COUNTY_LIMBAZU_NOV"
            }
        },
        {
            "name": "Skulte",
            "code": "CITY_SKULTE_LIMBAZU",
            "county": {
                "code": "COUNTY_LIMBAZU_NOV"
            }
        },
        {
            "name": "Ludza",
            "code": "CITY_LUDZA",
            "county": {
                "code": "COUNTY_LUDZAS_NOV"
            }
        },
        {
            "name": "Isnaudas pag.",
            "code": "CITY_ISNAUDAS_PAG",
            "county": {
                "code": "COUNTY_LUDZAS_NOV"
            }
        },
        {
            "name": "Pildas pag.",
            "code": "CITY_PILDAS_PAG",
            "county": {
                "code": "COUNTY_LUDZAS_NOV"
            }
        },
        {
            "name": "Ņukšu pag.",
            "code": "CITY_NUKSU_PAG",
            "county": {
                "code": "COUNTY_LUDZAS_NOV"
            }
        },
        {
            "name": "Briģu pag.",
            "code": "CITY_BRIGU_PAG",
            "county": {
                "code": "COUNTY_LUDZAS_NOV"
            }
        },
        {
            "name": "Nirzas pag.",
            "code": "CITY_NIRZAS_PAG",
            "county": {
                "code": "COUNTY_LUDZAS_NOV"
            }
        },
        {
            "name": "Istras pag.",
            "code": "CITY_ISTRAS_PAG",
            "county": {
                "code": "COUNTY_LUDZAS_NOV"
            }
        },
        {
            "name": "Cirmas pag.",
            "code": "CITY_CIRMAS_PAG",
            "county": {
                "code": "COUNTY_LUDZAS_NOV"
            }
        },
        {
            "name": "Rundēnu pag.",
            "code": "CITY_RUNDENU_PAG",
            "county": {
                "code": "COUNTY_LUDZAS_NOV"
            }
        },
        {
            "name": "Pureņu pag.",
            "code": "CITY_PURENU_PAG",
            "county": {
                "code": "COUNTY_LUDZAS_NOV"
            }
        },
        {
            "name": "Madona",
            "code": "CITY_MADONA",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Barkavas pag.",
            "code": "CITY_BARKAVAS_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Bērzaunes pag.",
            "code": "CITY_BERZAUNES_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Ļaudonas pag.",
            "code": "CITY_LAUDONAS_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Vestienas pag.",
            "code": "CITY_VESTIENAS_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Liezēres pag.",
            "code": "CITY_LIEZERES_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Sarkaņu pag.",
            "code": "CITY_SARKANU_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Kalsnavas pag.",
            "code": "CITY_KALSNAVAS_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Mētrienas pag.",
            "code": "CITY_METRIENAS_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Mārcienas pag.",
            "code": "CITY_MARCIENAS_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Praulienas pag.",
            "code": "CITY_PRAULIENAS_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Lazdonas pag.",
            "code": "CITY_LAZDONAS_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Dzelzavas pag.",
            "code": "CITY_DZELZAVAS_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Ošupes pag.",
            "code": "CITY_OSUPES_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Aronas pag.",
            "code": "CITY_ARONAS_PAG",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Barkava",
            "code": "CITY_BARKAVA",
            "county": {
                "code": "COUNTY_MADONAS_NOV"
            }
        },
        {
            "name": "Sidgunda",
            "code": "CITY_SIDGUNDA",
            "county": {
                "code": "COUNTY_MALPILS_NOV"
            }
        },
        {
            "name": "Bukas",
            "code": "CITY_BUKAS",
            "county": {
                "code": "COUNTY_MALPILS_NOV"
            }
        },
        {
            "name": "Vite",
            "code": "CITY_VITE",
            "county": {
                "code": "COUNTY_MALPILS_NOV"
            }
        },
        {
            "name": "Mālpils",
            "code": "CITY_MALPILS",
            "county": {
                "code": "COUNTY_MALPILS_NOV"
            }
        },
        {
            "name": "Upmalas",
            "code": "CITY_UPMALAS",
            "county": {
                "code": "COUNTY_MALPILS_NOV"
            }
        },
        {
            "name": "Mārupe",
            "code": "CITY_MARUPE",
            "county": {
                "code": "COUNTY_MARUPES_NOV"
            }
        },
        {
            "name": "Skulte",
            "code": "CITY_SKULTE",
            "county": {
                "code": "COUNTY_MARUPES_NOV"
            }
        },
        {
            "name": "Vētras",
            "code": "CITY_VETRAS",
            "county": {
                "code": "COUNTY_MARUPES_NOV"
            }
        },
        {
            "name": "Jaunmārupe",
            "code": "CITY_JAUNMARUPE",
            "county": {
                "code": "COUNTY_MARUPES_NOV"
            }
        },
        {
            "name": "Tīraine",
            "code": "CITY_TIRAINE",
            "county": {
                "code": "COUNTY_MARUPES_NOV"
            }
        },
        {
            "name": "Lidosta \"Rīga\"",
            "code": "CITY_LIDOSTA_RIGA",
            "county": {
                "code": "COUNTY_MARUPES_NOV"
            }
        },
        {
            "name": "Mazsalaca",
            "code": "CITY_MAZSALACA",
            "county": {
                "code": "COUNTY_MAZSALACAS_NOV"
            }
        },
        {
            "name": "Ramatas pag.",
            "code": "CITY_RAMATAS_PAG",
            "county": {
                "code": "COUNTY_MAZSALACAS_NOV"
            }
        },
        {
            "name": "Mazsalacas pag.",
            "code": "CITY_MAZSALACAS_PAG",
            "county": {
                "code": "COUNTY_MAZSALACAS_NOV"
            }
        },
        {
            "name": "Skaņkalnes pag.",
            "code": "CITY_SKANKALNES_PAG",
            "county": {
                "code": "COUNTY_MAZSALACAS_NOV"
            }
        },
        {
            "name": "Sēļu pag.",
            "code": "CITY_SELU_PAG",
            "county": {
                "code": "COUNTY_MAZSALACAS_NOV"
            }
        },
        {
            "name": "Ķoņu pag.",
            "code": "CITY_KONU_PAG",
            "county": {
                "code": "COUNTY_NAUKSENU_NOV"
            }
        },
        {
            "name": "Naukšēnu pag.",
            "code": "CITY_NAUKSENU_PAG",
            "county": {
                "code": "COUNTY_NAUKSENU_NOV"
            }
        },
        {
            "name": "Neretas pag.",
            "code": "CITY_NERETAS_PAG",
            "county": {
                "code": "COUNTY_NERETAS_NOV"
            }
        },
        {
            "name": "Pilskalnes pag.",
            "code": "CITY_PILSKALNES_NERETAS",
            "county": {
                "code": "COUNTY_NERETAS_NOV"
            }
        },
        {
            "name": "Zalves pag.",
            "code": "CITY_ZALVES_PAG",
            "county": {
                "code": "COUNTY_NERETAS_NOV"
            }
        },
        {
            "name": "Mazzalves pag.",
            "code": "CITY_MAZZALVES_PAG",
            "county": {
                "code": "COUNTY_NERETAS_NOV"
            }
        },
        {
            "name": "Nereta",
            "code": "CITY_NERETA",
            "county": {
                "code": "COUNTY_NERETAS_NOV"
            }
        },
        {
            "name": "Nīcas pag.",
            "code": "CITY_NICAS_PAG",
            "county": {
                "code": "COUNTY_NICAS_NOV"
            }
        },
        {
            "name": "Otaņķu pag.",
            "code": "CITY_OTANKU_PAG",
            "county": {
                "code": "COUNTY_NICAS_NOV"
            }
        },
        {
            "name": "Medemciems",
            "code": "CITY_MEDEMCIEMS",
            "county": {
                "code": "COUNTY_OLAINES_NOV"
            }
        },
        {
            "name": "Olaine",
            "code": "CITY_OLAINE",
            "county": {
                "code": "COUNTY_OLAINES_NOV"
            }
        },
        {
            "name": "Olaines pag.",
            "code": "CITY_OLAINES_PAG",
            "county": {
                "code": "COUNTY_OLAINES_NOV"
            }
        },
        {
            "name": "Jaunolaine",
            "code": "CITY_JAUNOLAINE",
            "county": {
                "code": "COUNTY_OLAINES_NOV"
            }
        },
        {
            "name": "Pārolaine",
            "code": "CITY_PAROLAINE",
            "county": {
                "code": "COUNTY_OLAINES_NOV"
            }
        },
        {
            "name": "Stūnīši",
            "code": "CITY_STUNISI",
            "county": {
                "code": "COUNTY_OLAINES_NOV"
            }
        },
        {
            "name": "Grēnes",
            "code": "CITY_GRENES",
            "county": {
                "code": "COUNTY_OLAINES_NOV"
            }
        },
        {
            "name": "Stalbes pag.",
            "code": "CITY_STALBES_PAG",
            "county": {
                "code": "COUNTY_PARGAUJAS_NOV"
            }
        },
        {
            "name": "Raiskuma pag.",
            "code": "CITY_RAISKUMA_PAG",
            "county": {
                "code": "COUNTY_PARGAUJAS_NOV"
            }
        },
        {
            "name": "Straupes pag.",
            "code": "CITY_STRAUPES_PAG",
            "county": {
                "code": "COUNTY_PARGAUJAS_NOV"
            }
        },
        {
            "name": "Pāvilosta",
            "code": "CITY_PAVILOSTA",
            "county": {
                "code": "COUNTY_PAVILOSTAS_NOV"
            }
        },
        {
            "name": "Sakas pag.",
            "code": "CITY_SAKAS_PAG",
            "county": {
                "code": "COUNTY_PAVILOSTAS_NOV"
            }
        },
        {
            "name": "Vērgales pag.",
            "code": "CITY_VERGALES_PAG",
            "county": {
                "code": "COUNTY_PAVILOSTAS_NOV"
            }
        },
        {
            "name": "Pļaviņas",
            "code": "CITY_PLAVINAS",
            "county": {
                "code": "COUNTY_PLAVINU_NOV"
            }
        },
        {
            "name": "Klintaines pag.",
            "code": "CITY_KLINTAINES_PAG",
            "county": {
                "code": "COUNTY_PLAVINU_NOV"
            }
        },
        {
            "name": "Vietalvas pag.",
            "code": "CITY_VIETALVAS_PAG",
            "county": {
                "code": "COUNTY_PLAVINU_NOV"
            }
        },
        {
            "name": "Aiviekstes pag.",
            "code": "CITY_AIVIEKSTES_PAG",
            "county": {
                "code": "COUNTY_PLAVINU_NOV"
            }
        },
        {
            "name": "Priekule",
            "code": "CITY_PRIEKULE",
            "county": {
                "code": "COUNTY_PRIEKULES_NOV"
            }
        },
        {
            "name": "Virgas pag.",
            "code": "CITY_VIRGAS_PAG",
            "county": {
                "code": "COUNTY_PRIEKULES_NOV"
            }
        },
        {
            "name": "Kalētu pag.",
            "code": "CITY_KALETU_PAG",
            "county": {
                "code": "COUNTY_PRIEKULES_NOV"
            }
        },
        {
            "name": "Priekules pag.",
            "code": "CITY_PRIEKULES_PAG",
            "county": {
                "code": "COUNTY_PRIEKULES_NOV"
            }
        },
        {
            "name": "Bunkas pag.",
            "code": "CITY_BUNKAS_PAG",
            "county": {
                "code": "COUNTY_PRIEKULES_NOV"
            }
        },
        {
            "name": "Gramzdas pag.",
            "code": "CITY_GRAMZDAS_PAG",
            "county": {
                "code": "COUNTY_PRIEKULES_NOV"
            }
        },
        {
            "name": "Liepas pag.",
            "code": "CITY_LIEPAS_PAG",
            "county": {
                "code": "COUNTY_PRIEKULU_NOV"
            }
        },
        {
            "name": "Veselavas pag.",
            "code": "CITY_VESELAVAS_PAG",
            "county": {
                "code": "COUNTY_PRIEKULU_NOV"
            }
        },
        {
            "name": "Priekuļu pag.",
            "code": "CITY_PRIEKULU_PAG",
            "county": {
                "code": "COUNTY_PRIEKULU_NOV"
            }
        },
        {
            "name": "Mārsnēnu pag.",
            "code": "CITY_MARSNENU_PAG",
            "county": {
                "code": "COUNTY_PRIEKULU_NOV"
            }
        },
        {
            "name": "Priekuļi",
            "code": "CITY_PRIEKUĻI",
            "county": {
                "code": "COUNTY_PRIEKULU_NOV"
            }
        },
        {
            "name": "Gaigalavas pag.",
            "code": "CITY_GAIGALAVAS_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Ozolmuižas pag.",
            "code": "CITY_OZOLMUIZAS_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Rikavas pag.",
            "code": "CITY_RIKAVAS_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Audriņu pag.",
            "code": "CITY_AUDRINU_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Ozolaines pag.",
            "code": "CITY_OZOLAINES_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Feimaņu pag.",
            "code": "CITY_FEIMANU_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Dricānu pag.",
            "code": "CITY_DRICANU_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Pušas pag.",
            "code": "CITY_PUSAS_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Čornajas pag.",
            "code": "CITY_CORNAJAS_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Griškānu pag.",
            "code": "CITY_GRISKANU_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Kaunatas pag.",
            "code": "CITY_KAUNATAS_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Nagļu pag.",
            "code": "CITY_NAGLU_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Sakstagala pag.",
            "code": "CITY_SAKSTAGALA_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Stoļerovas pag.",
            "code": "CITY_STOLEROVAS_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Lūznavas pag.",
            "code": "CITY_LUZNAVAS_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Mākoņkalna pag.",
            "code": "CITY_MAKONKALNA_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Stružānu pag.",
            "code": "CITY_STRUZANU_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Maltas pag.",
            "code": "CITY_MALTAS_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Nautrēnu pag.",
            "code": "CITY_NAUTRENU_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Lendžu pag.",
            "code": "CITY_LENDZU_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Bērzgales pag.",
            "code": "CITY_BERZGALES_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Silmalas pag.",
            "code": "CITY_SILMALAS_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Vērēmu pag.",
            "code": "CITY_VEREMU_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Kantinieku pag.",
            "code": "CITY_KANTINIEKU_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Ilzeskalna pag.",
            "code": "CITY_ILZESKALNA_PAG",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Malta",
            "code": "CITY_MALTA",
            "county": {
                "code": "COUNTY_REZEKNES_NOV"
            }
        },
        {
            "name": "Rucavas pag.",
            "code": "CITY_RUCAVAS_PAG",
            "county": {
                "code": "COUNTY_RUCAVAS_NOV"
            }
        },
        {
            "name": "Dunikas pag.",
            "code": "CITY_DUNIKAS_PAG",
            "county": {
                "code": "COUNTY_RUCAVAS_NOV"
            }
        },
        {
            "name": "Ječi",
            "code": "CITY_JECI",
            "county": {
                "code": "COUNTY_RUCAVAS_NOV"
            }
        },
        {
            "name": "Rugāju pag.",
            "code": "CITY_RUGAJU_PAG",
            "county": {
                "code": "COUNTY_RUGAJU_NOV"
            }
        },
        {
            "name": "Lazdukalna pag.",
            "code": "CITY_LAZDUKALNA_PAG",
            "county": {
                "code": "COUNTY_RUGAJU_NOV"
            }
        },
        {
            "name": "Svitenes pag.",
            "code": "CITY_SVITENES_PAG",
            "county": {
                "code": "COUNTY_RUNDALES_NOV"
            }
        },
        {
            "name": "Rundāles pag.",
            "code": "CITY_RUNDALES_PAG",
            "county": {
                "code": "COUNTY_RUNDALES_NOV"
            }
        },
        {
            "name": "Viesturu pag.",
            "code": "CITY_VIESTURU_PAG",
            "county": {
                "code": "COUNTY_RUNDALES_NOV"
            }
        },
        {
            "name": "Rūjiena",
            "code": "CITY_RUJIENA",
            "county": {
                "code": "COUNTY_RUJIENAS_NOV"
            }
        },
        {
            "name": "Ipiķu pag.",
            "code": "CITY_IPIKU_PAG",
            "county": {
                "code": "COUNTY_RUJIENAS_NOV"
            }
        },
        {
            "name": "Vilpulkas pag.",
            "code": "CITY_VILPULKAS_PAG",
            "county": {
                "code": "COUNTY_RUJIENAS_NOV"
            }
        },
        {
            "name": "Lodes pag.",
            "code": "CITY_LODES_PAG",
            "county": {
                "code": "COUNTY_RUJIENAS_NOV"
            }
        },
        {
            "name": "Jeru pag.",
            "code": "CITY_JERU_PAG",
            "county": {
                "code": "COUNTY_RUJIENAS_NOV"
            }
        },
        {
            "name": "Ainaži",
            "code": "CITY_AINAZI",
            "county": {
                "code": "COUNTY_SALACGRIVAS_NOV"
            }
        },
        {
            "name": "Salacgrīva",
            "code": "CITY_SALACGRIVA",
            "county": {
                "code": "COUNTY_SALACGRIVAS_NOV"
            }
        },
        {
            "name": "Liepupes pag.",
            "code": "CITY_LIEPUPES_PAG",
            "county": {
                "code": "COUNTY_SALACGRIVAS_NOV"
            }
        },
        {
            "name": "Ainažu pag.",
            "code": "CITY_AINAZU_PAG",
            "county": {
                "code": "COUNTY_SALACGRIVAS_NOV"
            }
        },
        {
            "name": "Salacgrīvas pag.",
            "code": "CITY_SALACGRIVAS_PAG",
            "county": {
                "code": "COUNTY_SALACGRIVAS_NOV"
            }
        },
        {
            "name": "Tūja",
            "code": "CITY_TUJA",
            "county": {
                "code": "COUNTY_SALACGRIVAS_NOV"
            }
        },
        {
            "name": "Lāņi",
            "code": "CITY_LANI",
            "county": {
                "code": "COUNTY_SALACGRIVAS_NOV"
            }
        },
        {
            "name": "Sēlpils pag.",
            "code": "CITY_SELPILS_PAG",
            "county": {
                "code": "COUNTY_SALAS_NOV"
            }
        },
        {
            "name": "Salas pag.",
            "code": "CITY_SALAS_SALAS",
            "county": {
                "code": "COUNTY_SALAS_NOV"
            }
        },
        {
            "name": "Saldus",
            "code": "CITY_SALDUS",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Rubas pag.",
            "code": "CITY_RUBAS_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Šķēdes pag.",
            "code": "CITY_SKEDES_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Jaunauces pag.",
            "code": "CITY_JAUNAUCES_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Nīgrandes pag.",
            "code": "CITY_NIGRANDES_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Zaņas pag.",
            "code": "CITY_ZANAS_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Zirņu pag.",
            "code": "CITY_ZIRNU_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Jaunlutriņu pag.",
            "code": "CITY_JAUNLUTRINU_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Pampāļu pag.",
            "code": "CITY_PAMPALU_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Vadakstes pag.",
            "code": "CITY_VADAKSTES_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Zvārdes pag.",
            "code": "CITY_ZVARDES_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Kursīšu pag.",
            "code": "CITY_KURSISU_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Saldus pag.",
            "code": "CITY_SALDUS_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Ezeres pag.",
            "code": "CITY_EZERES_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Novadnieku pag.",
            "code": "CITY_NOVADNIEKU_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Lutriņu pag.",
            "code": "CITY_LUTRINU_PAG",
            "county": {
                "code": "COUNTY_SALDUS_NOV"
            }
        },
        {
            "name": "Skrīveri",
            "code": "CITY_SKRIVERI",
            "county": {
                "code": "COUNTY_SKRIVERU_NOV"
            }
        },
        {
            "name": "Ziedugravas",
            "code": "CITY_ZIEDUGRAVAS",
            "county": {
                "code": "COUNTY_SKRIVERU_NOV"
            }
        },
        {
            "name": "Zemkopības institūts",
            "code": "CITY_ZEMKOPIBAS_INSTITUTS",
            "county": {
                "code": "COUNTY_SKRIVERU_NOV"
            }
        },
        {
            "name": "Klidziņa",
            "code": "CITY_KLIDZINA",
            "county": {
                "code": "COUNTY_SKRIVERU_NOV"
            }
        },
        {
            "name": "Līči",
            "code": "CITY_LICI",
            "county": {
                "code": "COUNTY_SKRIVERU_NOV"
            }
        },
        {
            "name": "Skrunda",
            "code": "CITY_SKRUNDA",
            "county": {
                "code": "COUNTY_SKRUNDAS_NOV"
            }
        },
        {
            "name": "Raņķu pag.",
            "code": "CITY_RANKU_PAG",
            "county": {
                "code": "COUNTY_SKRUNDAS_NOV"
            }
        },
        {
            "name": "Nīkrāces pag.",
            "code": "CITY_NIKRACES_PAG",
            "county": {
                "code": "COUNTY_SKRUNDAS_NOV"
            }
        },
        {
            "name": "Rudbāržu pag.",
            "code": "CITY_RUDBARZU_PAG",
            "county": {
                "code": "COUNTY_SKRUNDAS_NOV"
            }
        },
        {
            "name": "Skrundas pag.",
            "code": "CITY_SKRUNDAS_PAG",
            "county": {
                "code": "COUNTY_SKRUNDAS_NOV"
            }
        },
        {
            "name": "Smiltene",
            "code": "CITY_SMILTENE",
            "county": {
                "code": "COUNTY_SMILTENES_NOV"
            }
        },
        {
            "name": "Blomes pag.",
            "code": "CITY_BLOMES_PAG",
            "county": {
                "code": "COUNTY_SMILTENES_NOV"
            }
        },
        {
            "name": "Palsmanes pag.",
            "code": "CITY_PALSMANES_PAG",
            "county": {
                "code": "COUNTY_SMILTENES_NOV"
            }
        },
        {
            "name": "Bilskas pag.",
            "code": "CITY_BILSKAS_PAG",
            "county": {
                "code": "COUNTY_SMILTENES_NOV"
            }
        },
        {
            "name": "Launkalnes pag.",
            "code": "CITY_LAUNKALNES_PAG",
            "county": {
                "code": "COUNTY_SMILTENES_NOV"
            }
        },
        {
            "name": "Smiltenes pag.",
            "code": "CITY_SMILTENES_PAG",
            "county": {
                "code": "COUNTY_SMILTENES_NOV"
            }
        },
        {
            "name": "Variņu pag.",
            "code": "CITY_VARINU_PAG",
            "county": {
                "code": "COUNTY_SMILTENES_NOV"
            }
        },
        {
            "name": "Grundzāles pag.",
            "code": "CITY_GRUNDZALES_PAG",
            "county": {
                "code": "COUNTY_SMILTENES_NOV"
            }
        },
        {
            "name": "Brantu pag.",
            "code": "CITY_BRANTU_PAG",
            "county": {
                "code": "COUNTY_SMILTENES_NOV"
            }
        },
        {
            "name": "Seda",
            "code": "CITY_SEDA",
            "county": {
                "code": "COUNTY_STRENCU_NOV"
            }
        },
        {
            "name": "Strenči",
            "code": "CITY_STENCI",
            "county": {
                "code": "COUNTY_STRENCU_NOV"
            }
        },
        {
            "name": "Plāņu pag.",
            "code": "CITY_PLANU_PAG",
            "county": {
                "code": "COUNTY_STRENCU_NOV"
            }
        },
        {
            "name": "Jērcēnu pag.",
            "code": "CITY_JERCENU_PAG",
            "county": {
                "code": "COUNTY_STRENCU_NOV"
            }
        },
        {
            "name": "Stende",
            "code": "CITY_STENDE",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Sabile",
            "code": "CITY_SABILE",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Talsi",
            "code": "CITY_TALSI",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Valdemārpils",
            "code": "CITY_VALDEMARPILS",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Laidzes pag.",
            "code": "CITY_LAIDZES_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Valdgales pag.",
            "code": "CITY_VALDGALES_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Ārlavas pag.",
            "code": "CITY_ARLAVAS_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Ģibuļu pag.",
            "code": "CITY_GIBULU_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Īves pag.",
            "code": "CITY_IVES_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Lubes pag.",
            "code": "CITY_LUBES_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Strazdes pag.",
            "code": "CITY_STRAZDES_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Virbu pag.",
            "code": "CITY_VIRBU_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Balgales pag.",
            "code": "CITY_BALGALES_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Ķūļciema pag.",
            "code": "CITY_KULCIEMA_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Lībagu pag.",
            "code": "CITY_LIBAGU_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Laucienes pag.",
            "code": "CITY_LAUCIENAS_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Vandzenes pag.",
            "code": "CITY_VANDZENES_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Abavas pag.",
            "code": "CITY_ABAVAS_PAG",
            "county": {
                "code": "COUNTY_TALSU_NOV"
            }
        },
        {
            "name": "Tukums",
            "code": "CITY_TUKUMS",
            "county": {
                "code": "COUNTY_TUKUMA_NOV"
            }
        },
        {
            "name": "Džūkstes pag.",
            "code": "CITY_DZUKSTES_PAG",
            "county": {
                "code": "COUNTY_TUKUMA_NOV"
            }
        },
        {
            "name": "Jaunsātu pag.",
            "code": "CITY_JAUNSATU_PAG",
            "county": {
                "code": "COUNTY_TUKUMA_NOV"
            }
        },
        {
            "name": "Lestenes pag.",
            "code": "CITY_LESTENES_PAG",
            "county": {
                "code": "COUNTY_TUKUMA_NOV"
            }
        },
        {
            "name": "Sēmes pag.",
            "code": "CITY_SEMES_PAG",
            "county": {
                "code": "COUNTY_TUKUMA_NOV"
            }
        },
        {
            "name": "Tumes pag.",
            "code": "CITY_TUMES_PAG",
            "county": {
                "code": "COUNTY_TUKUMA_NOV"
            }
        },
        {
            "name": "Slampes pag.",
            "code": "CITY_SLAMPES_PAG",
            "county": {
                "code": "COUNTY_TUKUMA_NOV"
            }
        },
        {
            "name": "Irlavas pag.",
            "code": "CITY_IRLAVAS_PAG",
            "county": {
                "code": "COUNTY_TUKUMA_NOV"
            }
        },
        {
            "name": "Pūres pag.",
            "code": "CITY_PURES_PAG",
            "county": {
                "code": "COUNTY_TUKUMA_NOV"
            }
        },
        {
            "name": "Degoles pag.",
            "code": "CITY_DEGOLES_PAG",
            "county": {
                "code": "COUNTY_TUKUMA_NOV"
            }
        },
        {
            "name": "Zentenes pag.",
            "code": "CITY_ZENTENES_PAG",
            "county": {
                "code": "COUNTY_TUKUMA_NOV"
            }
        },
        {
            "name": "Embūtes pag.",
            "code": "CITY_EMBUTES_PAG",
            "county": {
                "code": "COUNTY_VAINODES_NOV"
            }
        },
        {
            "name": "Vaiņodes pag.",
            "code": "CITY_VAINODES_PAG",
            "county": {
                "code": "COUNTY_VAINODES_NOV"
            }
        },
        {
            "name": "Vaiņode",
            "code": "CITY_VAINODE",
            "county": {
                "code": "COUNTY_VAINODES_NOV"
            }
        },
        {
            "name": "Valka",
            "code": "CITY_VALKA",
            "county": {
                "code": "COUNTY_VALKAS_NOV"
            }
        },
        {
            "name": "Kārķu pag.",
            "code": "CITY_KARKU_PAG",
            "county": {
                "code": "COUNTY_VALKAS_NOV"
            }
        },
        {
            "name": "Valkas pag.",
            "code": "CITY_VALKAS_PAG",
            "county": {
                "code": "COUNTY_VALKAS_NOV"
            }
        },
        {
            "name": "Vijciema pag.",
            "code": "CITY_VIJCIEMA_PAG",
            "county": {
                "code": "COUNTY_VALKAS_NOV"
            }
        },
        {
            "name": "Zvārtavas pag.",
            "code": "CITY_ZVARTAVAS_PAG",
            "county": {
                "code": "COUNTY_VALKAS_NOV"
            }
        },
        {
            "name": "Ērģemes pag.",
            "code": "CITY_ERGEMES_PAG",
            "county": {
                "code": "COUNTY_VALKAS_NOV"
            }
        },
        {
            "name": "Dikļu pag.",
            "code": "CITY_DIKLU_PAG",
            "county": {
                "code": "COUNTY_KOCENU_NOV"
            }
        },
        {
            "name": "Kocēnu pag.",
            "code": "CITY_KOCENU_PAG",
            "county": {
                "code": "COUNTY_KOCENU_NOV"
            }
        },
        {
            "name": "Zilākalna pag.",
            "code": "CITY_ZILAKALNA_PAG",
            "county": {
                "code": "COUNTY_KOCENU_NOV"
            }
        },
        {
            "name": "Bērzaines pag.",
            "code": "CITY_BERZAINES_PAG",
            "county": {
                "code": "COUNTY_KOCENU_NOV"
            }
        },
        {
            "name": "Vaidavas pag.",
            "code": "CITY_VAIDAVAS_PAG",
            "county": {
                "code": "COUNTY_KOCENU_NOV"
            }
        },
        {
            "name": "Kocēni",
            "code": "CITY_KOCENI",
            "county": {
                "code": "COUNTY_KOCENU_NOV"
            }
        },
        {
            "name": "Varakļāni",
            "code": "CITY_VARAKLANI",
            "county": {
                "code": "COUNTY_VARAKLANU_NOV"
            }
        },
        {
            "name": "Varakļānu pag.",
            "code": "CITY_VARAKLANU_PAG",
            "county": {
                "code": "COUNTY_VARAKLANU_NOV"
            }
        },
        {
            "name": "Murmastienes pag.",
            "code": "CITY_MURMASTIENES_PAG",
            "county": {
                "code": "COUNTY_VARAKLANU_NOV"
            }
        },
        {
            "name": "Inešu pag.",
            "code": "CITY_INESU_PAG",
            "county": {
                "code": "COUNTY_VECPIEBALGAS_NOV"
            }
        },
        {
            "name": "Taurenes pag.",
            "code": "CITY_TAURENES_PAG",
            "county": {
                "code": "COUNTY_VECPIEBALGAS_NOV"
            }
        },
        {
            "name": "Dzērbenes pag.",
            "code": "CITY_DZERBENES_PAG",
            "county": {
                "code": "COUNTY_VECPIEBALGAS_NOV"
            }
        },
        {
            "name": "Kaives pag.",
            "code": "CITY_KAIVES_PAG",
            "county": {
                "code": "COUNTY_VECPIEBALGAS_NOV"
            }
        },
        {
            "name": "Vecpiebalgas pag.",
            "code": "CITY_VECPIEBALGAS_PAG",
            "county": {
                "code": "COUNTY_VECPIEBALGAS_NOV"
            }
        },
        {
            "name": "Vecpiebalga",
            "code": "CITY_VECPIEBALGA",
            "county": {
                "code": "COUNTY_VECPIEBALGAS_NOV"
            }
        },
        {
            "name": "Stelpes pag.",
            "code": "CITY_STELPES_PAG",
            "county": {
                "code": "COUNTY_VECUMNIEKU_NOV"
            }
        },
        {
            "name": "Bārbeles pag.",
            "code": "CITY_BARBELES_PAG",
            "county": {
                "code": "COUNTY_VECUMNIEKU_NOV"
            }
        },
        {
            "name": "Kurmenes pag.",
            "code": "CITY_KURMENES_PAG",
            "county": {
                "code": "COUNTY_VECUMNIEKU_NOV"
            }
        },
        {
            "name": "Skaistkalnes pag.",
            "code": "CITY_SKAISTKALNES_PAG",
            "county": {
                "code": "COUNTY_VECUMNIEKU_NOV"
            }
        },
        {
            "name": "Vecumnieku pag.",
            "code": "CITY_VECUMNIEKU_PAG",
            "county": {
                "code": "COUNTY_VECUMNIEKU_NOV"
            }
        },
        {
            "name": "Valles pag.",
            "code": "CITY_VALLES_PAG",
            "county": {
                "code": "COUNTY_VECUMNIEKU_NOV"
            }
        },
        {
            "name": "Skaistkalne",
            "code": "CITY_SKAISTKALNE",
            "county": {
                "code": "COUNTY_VECUMNIEKU_NOV"
            }
        },
        {
            "name": "Vecumnieki",
            "code": "CITY_VECUMNIEKI",
            "county": {
                "code": "COUNTY_VECUMNIEKU_NOV"
            }
        },
        {
            "name": "Piltene",
            "code": "CITY_PILTENE",
            "county": {
                "code": "COUNTY_VENTSPILS_NOV"
            }
        },
        {
            "name": "Puzes pag.",
            "code": "CITY_PUZES_PAG",
            "county": {
                "code": "COUNTY_VENTSPILS_NOV"
            }
        },
        {
            "name": "Piltenes pag.",
            "code": "CITY_PILTENES_PAG",
            "county": {
                "code": "COUNTY_VENTSPILS_NOV"
            }
        },
        {
            "name": "Usmas pag.",
            "code": "CITY_USMAS_PAG",
            "county": {
                "code": "COUNTY_VENTSPILS_NOV"
            }
        },
        {
            "name": "Ziru pag.",
            "code": "CITY_ZIRU_PAG",
            "county": {
                "code": "COUNTY_VENTSPILS_NOV"
            }
        },
        {
            "name": "Ances pag.",
            "code": "CITY_ANCES_PAG",
            "county": {
                "code": "COUNTY_VENTSPILS_NOV"
            }
        },
        {
            "name": "Jūrkalnes pag.",
            "code": "CITY_JURKALNES_PAG",
            "county": {
                "code": "COUNTY_VENTSPILS_NOV"
            }
        },
        {
            "name": "Užavas pag.",
            "code": "CITY_UZAVAS_PAG",
            "county": {
                "code": "COUNTY_VENTSPILS_NOV"
            }
        },
        {
            "name": "Ugāles pag.",
            "code": "CITY_UGALES_PAG",
            "county": {
                "code": "COUNTY_VENTSPILS_NOV"
            }
        },
        {
            "name": "Popes pag.",
            "code": "CITY_POPES_PAG",
            "county": {
                "code": "COUNTY_VENTSPILS_NOV"
            }
        },
        {
            "name": "Zlēku pag.",
            "code": "CITY_ZLEKU_PAG",
            "county": {
                "code": "COUNTY_VENTSPILS_NOV"
            }
        },
        {
            "name": "Tārgales pag.",
            "code": "CITY_TARGALES_PAG",
            "county": {
                "code": "COUNTY_VENTSPILS_NOV"
            }
        },
        {
            "name": "Vārves pag.",
            "code": "CITY_VARVES_PAG",
            "county": {
                "code": "COUNTY_VENTSPILS_NOV"
            }
        },
        {
            "name": "Viesīte",
            "code": "CITY_VIESITE",
            "county": {
                "code": "COUNTY_VIESITES_NOV"
            }
        },
        {
            "name": "Saukas pag.",
            "code": "CITY_SAUKAS_PAG",
            "county": {
                "code": "COUNTY_VIESITES_NOV"
            }
        },
        {
            "name": "Rites pag.",
            "code": "CITY_RITES_PAG",
            "county": {
                "code": "COUNTY_VIESITES_NOV"
            }
        },
        {
            "name": "Viesītes pag.",
            "code": "CITY_VIESITES_PAG",
            "county": {
                "code": "COUNTY_VIESITES_NOV"
            }
        },
        {
            "name": "Elkšņu pag.",
            "code": "CITY_ELKSNU_PAG",
            "county": {
                "code": "COUNTY_VIESITES_NOV"
            }
        },
        {
            "name": "Viļaka",
            "code": "CITY_VILAKA",
            "county": {
                "code": "COUNTY_VILAKAS_NOV"
            }
        },
        {
            "name": "Kupravas pag.",
            "code": "CITY_KUPRAVAS_PAG",
            "county": {
                "code": "COUNTY_VILAKAS_NOV"
            }
        },
        {
            "name": "Vecumu pag.",
            "code": "CITY_VECUMU_PAG",
            "county": {
                "code": "COUNTY_VILAKAS_NOV"
            }
        },
        {
            "name": "Susāju pag.",
            "code": "CITY_SUSAJU_PAG",
            "county": {
                "code": "COUNTY_VILAKAS_NOV"
            }
        },
        {
            "name": "Žīguru pag.",
            "code": "CITY_ZIGURU_PAG",
            "county": {
                "code": "COUNTY_VILAKAS_NOV"
            }
        },
        {
            "name": "Medņevas pag.",
            "code": "CITY_MEDNEVAS_PAG",
            "county": {
                "code": "COUNTY_VILAKAS_NOV"
            }
        },
        {
            "name": "Šķilbēnu pag.",
            "code": "CITY_SKILBENU_PAG",
            "county": {
                "code": "COUNTY_VILAKAS_NOV"
            }
        },
        {
            "name": "Viļāni",
            "code": "CITY_VILANI",
            "county": {
                "code": "COUNTY_VILANU_NOV"
            }
        },
        {
            "name": "Sokolku pag.",
            "code": "CITY_SOKOLKU_PAG",
            "county": {
                "code": "COUNTY_VILANU_NOV"
            }
        },
        {
            "name": "Dekšāres pag.",
            "code": "CITY_DEKSARES_PAG",
            "county": {
                "code": "COUNTY_VILANU_NOV"
            }
        },
        {
            "name": "Viļānu pag.",
            "code": "CITY_VILANU_PAG",
            "county": {
                "code": "COUNTY_VILANU_NOV"
            }
        },
        {
            "name": "Vecates pag.",
            "code": "CITY_VECATES_PAG",
            "county": {
                "code": "COUNTY_BURTNIEKU_NOV"
            }
        },
        {
            "name": "Burtnieku pag.",
            "code": "CITY_BURTNIEKU_PAG",
            "county": {
                "code": "COUNTY_BURTNIEKU_NOV"
            }
        },
        {
            "name": "Rencēnu pag.",
            "code": "CITY_RENCENU_PAG",
            "county": {
                "code": "COUNTY_BURTNIEKU_NOV"
            }
        },
        {
            "name": "Valmieras pag.",
            "code": "CITY_VALMIERAS_PAG",
            "county": {
                "code": "COUNTY_BURTNIEKU_NOV"
            }
        },
        {
            "name": "Ēveles pag.",
            "code": "CITY_EVELES_PAG",
            "county": {
                "code": "COUNTY_BURTNIEKU_NOV"
            }
        },
        {
            "name": "Matīšu pag.",
            "code": "CITY_MATISU_PAG",
            "county": {
                "code": "COUNTY_BURTNIEKU_NOV"
            }
        },
        {
            "name": "Valmiermuiža",
            "code": "CITY_VALMIERMUIŽA",
            "county": {
                "code": "COUNTY_BURTNIEKU_NOV"
            }
        },
        {
            "name": "Jumurdas pag.",
            "code": "CITY_JUMURDAS_PAG",
            "county": {
                "code": "COUNTY_ERGLU_NOV"
            }
        },
        {
            "name": "Sausnējas pag.",
            "code": "CITY_SAUSNEJAS_PAG",
            "county": {
                "code": "COUNTY_ERGLU_NOV"
            }
        },
        {
            "name": "Ērgļu pag.",
            "code": "CITY_ERGLU_PAG",
            "county": {
                "code": "COUNTY_ERGLU_NOV"
            }
        },
        {
            "name": "Ērgļi",
            "code": "CITY_ERGLI",
            "county": {
                "code": "COUNTY_ERGLU_NOV"
            }
        },
        {
            "name": "Pabaži",
            "code": "CITY_PABAZI",
            "county": {
                "code": "COUNTY_SEJAS_NOV"
            }
        },
        {
            "name": "Ābelīte",
            "code": "CITY_ABELITE",
            "county": {
                "code": "COUNTY_SEJAS_NOV"
            }
        },
        {
            "name": "Vaivariņi",
            "code": "CITY_VAIVARINI",
            "county": {
                "code": "COUNTY_SEJAS_NOV"
            }
        },
        {
            "name": "Gāršmuiža",
            "code": "CITY_GARSMUIZA",
            "county": {
                "code": "COUNTY_SEJAS_NOV"
            }
        },
        {
            "name": "Jaunkrimulda",
            "code": "CITY_JAUNKRIMULDA",
            "county": {
                "code": "COUNTY_SEJAS_NOV"
            }
        },
        {
            "name": "Sēja",
            "code": "CITY_SEJA",
            "county": {
                "code": "COUNTY_SEJAS_NOV"
            }
        },
        {
            "name": "Murjāņi",
            "code": "CITY_MURJANI",
            "county": {
                "code": "COUNTY_SEJAS_NOV"
            }
        },
        {
            "name": "Loja",
            "code": "CITY_LOJA",
            "county": {
                "code": "COUNTY_SEJAS_NOV"
            }
        },
        {
            "name": "Vangaži",
            "code": "CITY_VANGAZI",
            "county": {
                "code": "COUNTY_INCUKALNA_NOV"
            }
        },
        {
            "name": "Inčukalna pag.",
            "code": "CITY_INCUKALNA_PAG",
            "county": {
                "code": "COUNTY_INCUKALNA_NOV"
            }
        },
        {
            "name": "Inčukalns",
            "code": "CITY_INCUKALNS",
            "county": {
                "code": "COUNTY_INCUKALNA_NOV"
            }
        },
        {
            "name": "Egļupe",
            "code": "CITY_EGLUPE",
            "county": {
                "code": "COUNTY_INCUKALNA_NOV"
            }
        },
        {
            "name": "Jaunpiebalgas pag.",
            "code": "CITY_JAUNPIEBALGAS_PAG",
            "county": {
                "code": "COUNTY_JAUNPIEBALGAS_NOV"
            }
        },
        {
            "name": "Zosēnu pag.",
            "code": "CITY_ZOSENU_PAG",
            "county": {
                "code": "COUNTY_JAUNPIEBALGAS_NOV"
            }
        },
        {
            "name": "Saulkrasti",
            "code": "CITY_SAULKRASTI",
            "county": {
                "code": "COUNTY_SAULKRASTU_NOV"
            }
        },
        {
            "name": "Saulkrastu pag.",
            "code": "CITY_SAULKRASTU_PAG",
            "county": {
                "code": "COUNTY_SAULKRASTU_NOV"
            }
        },
        {
            "name": "Zvejniekciems",
            "code": "CITY_ZVEJNIEKCIEMS",
            "county": {
                "code": "COUNTY_SAULKRASTU_NOV"
            }
        },
        {
            "name": "Drustu pag.",
            "code": "CITY_DRUSTU_PAG",
            "county": {
                "code": "COUNTY_RAUNAS_NOV"
            }
        },
        {
            "name": "Raunas pag.",
            "code": "CITY_RAUNAS_PAG",
            "county": {
                "code": "COUNTY_RAUNAS_NOV"
            }
        },
        {
            "name": "Rauna",
            "code": "CITY_RAUNA",
            "county": {
                "code": "COUNTY_RAUNAS_NOV"
            }
        },
        {
            "name": "Lielvārde",
            "code": "CITY_LIELVARDE",
            "county": {
                "code": "COUNTY_LIELVARDES_NOV"
            }
        },
        {
            "name": "Jumpravas pag.",
            "code": "CITY_JUMPRAVAS_PAG",
            "county": {
                "code": "COUNTY_LIELVARDES_NOV"
            }
        },
        {
            "name": "Lielvārdes pag.",
            "code": "CITY_LIELVARDES_PAG",
            "county": {
                "code": "COUNTY_LIELVARDES_NOV"
            }
        },
        {
            "name": "Lēdmanes pag.",
            "code": "CITY_LEDMANES_PAG",
            "county": {
                "code": "COUNTY_LIELVARDES_NOV"
            }
        },
        {
            "name": "Ropaži",
            "code": "CITY_ROPAZI",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Zaķumuiža",
            "code": "CITY_ZAKUMUIZA",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Lielkangari",
            "code": "CITY_LIELKANGARI",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Silakrogs",
            "code": "CITY_SILAKROGS",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Tumšupe",
            "code": "CITY_TUMSUPE",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Augšciems",
            "code": "CITY_AUGSCIEMS",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Bajāri",
            "code": "CITY_BAJARI",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Vāverkrogs",
            "code": "CITY_VAVERKROGS",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Villasmuiža",
            "code": "CITY_VILLASMUIZA",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Kākciems",
            "code": "CITY_KAKCIEMS",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Gaidas",
            "code": "CITY_GAIDAS",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Ūlupji",
            "code": "CITY_ULUPJI",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Nāgelmuiža",
            "code": "CITY_NAGELMUIZA",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Podkājas",
            "code": "CITY_PODKAJAS",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Mucenieki",
            "code": "CITY_MUCENIEKI",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Rotkaļi",
            "code": "CITY_ROTKALI",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Jaunbagumi",
            "code": "CITY_JAUNBAGUMI",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Jaunmucenieki",
            "code": "CITY_JAUNMUCENIEKI",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Kangari",
            "code": "CITY_KANGARI",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Bajārkrogs",
            "code": "CITY_BAJARKROGS",
            "county": {
                "code": "COUNTY_ROPAZU_NOV"
            }
        },
        {
            "name": "Silajāņu pag.",
            "code": "CITY_SILAJANU_PAG",
            "county": {
                "code": "COUNTY_RIEBINU_NOV"
            }
        },
        {
            "name": "Galēnu pag.",
            "code": "CITY_GALENU_PAG",
            "county": {
                "code": "COUNTY_RIEBINU_NOV"
            }
        },
        {
            "name": "Sīļukalna pag.",
            "code": "CITY_SILUKALNA_PAG",
            "county": {
                "code": "COUNTY_RIEBINU_NOV"
            }
        },
        {
            "name": "Stabulnieku pag.",
            "code": "CITY_STABULNIEKU_PAG",
            "county": {
                "code": "COUNTY_RIEBINU_NOV"
            }
        },
        {
            "name": "Riebiņu pag.",
            "code": "CITY_RIEBINU_PAG",
            "county": {
                "code": "COUNTY_RIEBINU_NOV"
            }
        },
        {
            "name": "Rušonas pag.",
            "code": "CITY_RUSONAS_PAG",
            "county": {
                "code": "COUNTY_RIEBINU_NOV"
            }
        },
        {
            "name": "Ulbroka",
            "code": "CITY_ULBROKA",
            "county": {
                "code": "COUNTY_STOPINU_NOV"
            }
        },
        {
            "name": "Līči",
            "code": "CITY_LICI",
            "county": {
                "code": "COUNTY_STOPINU_NOV"
            }
        },
        {
            "name": "Saulīšu ciems",
            "code": "CITY_SAULISU_CIEMS",
            "county": {
                "code": "COUNTY_STOPINU_NOV"
            }
        },
        {
            "name": "Saurieši",
            "code": "CITY_SAURIESI",
            "county": {
                "code": "COUNTY_STOPINU_NOV"
            }
        },
        {
            "name": "Cekule",
            "code": "CITY_CEKULE",
            "county": {
                "code": "COUNTY_STOPINU_NOV"
            }
        },
        {
            "name": "Upeslejas",
            "code": "CITY_UPESLEJAS",
            "county": {
                "code": "COUNTY_STOPINU_NOV"
            }
        },
        {
            "name": "Vālodzes",
            "code": "CITY_VALODZES",
            "county": {
                "code": "COUNTY_STOPINU_NOV"
            }
        },
        {
            "name": "Dzidriņas",
            "code": "CITY_DZIDRINAS",
            "county": {
                "code": "COUNTY_STOPINU_NOV"
            }
        },
        {
            "name": "Stopiņu pag.",
            "code": "CITY_STOPINU_PAG",
            "county": {
                "code": "COUNTY_STOPINU_NOV"
            }
        },
        {
            "name": "Salaspils",
            "code": "CITY_SALASPILS",
            "county": {
                "code": "COUNTY_SALASPILS_NOV"
            }
        },
        {
            "name": "Salaspils pag.",
            "code": "CITY_SALASPILS_PAG",
            "county": {
                "code": "COUNTY_SALASPILS_NOV"
            }
        }
    ]
}